import styled, { css } from 'styled-components';
import Tooltip from 'components/utils/Tooltip';

import { main, states } from 'styles/colors';

import IStyledContainerProperties from '../_shared/interfaces/styledContainerProperties';

export const Container = styled.div<IStyledContainerProperties>`
  border-radius: 4px;
  width: 100%;
  color: ${main.BROWNBAGE};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;

  ${(props) =>
    props.isErrored &&
    css`
      border: 2px solid ${states.ERROR};
    `}

  > span {
    display: flex;
    flex: 1;
  }

  input {
    -webkit-appearance: none;
    width: 20px;
    height: 20px;

    border: 1px solid ${main.GREEN};
    border-radius: 2px;
    margin-right: 5px;

    display: flex;
    align-items: center;
    justify-content: center;

    &:checked,
    &:active {
      background-color: ${main.GREEN};
    }

    &:checked:after {
      content: '✘';
      font-size: 18px;
      color: ${main.WHITE};
    }
  }

  svg {
    margin-right: 5px;
  }
`;

export const Error = styled(Tooltip)`
  height: 20px;
  margin-left: 5px;

  svg {
    margin: 0;
  }

  span {
    background: ${states.ERROR};
    color: ${main.WHITE};

    &::before {
      border-color: ${states.ERROR} transparent;
    }
  }
`;
