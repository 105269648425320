import styled from 'styled-components';
import { shade } from 'polished';

import { main } from 'styles/colors';

export const Container = styled.div`
  z-index: 1000;
  box-sizing: border-box;
  position: absolute;
  top: 0;

  width: 100%;
  height: 8.5vh;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  background-color: ${main.WHITE};

  box-shadow: -5px 0px 3px ${shade(0.3, main.BLACK)};

  > section {
    padding-left: 16px;

    display: flex;
    flex: 1;

    flex-direction: row;
    align-items: center;
    justify-content: flex-start;

    text-align: center;

    > img {
      width: 40px;
      height: 40px;

      border-radius: 50%;
      border: 1px solid ${main.GREEN};

      margin-right: 8px;
    }

    > span {
      display: flex;
      align-items: center;
      justify-content: center;

      > img {
        height: 12px;
      }
    }

    > h4 {
      color: ${main.BROWN};
      text-transform: lowercase;
    }
  }

  > div {
    display: flex;
    flex: 1;
    height: 100%;

    flex-direction: row;
    justify-content: flex-end;

    > section {
      background-color: #000;
      width: 8.5vh;
      height: 100%;
      align-self: flex-end;
    }
  }
`;
