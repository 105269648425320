import React from 'react';

import { Container } from './styles';

import ITooltipProperties from './interfaces/tooltipProperties';

const Tooltip: React.FC<ITooltipProperties> = ({
  title,
  className,
  children,
}) => {
  return (
    <Container className={className}>
      {children}
      <span>{title}</span>
    </Container>
  );
};

export default Tooltip;
