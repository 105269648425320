import styled from 'styled-components';
import { lighten } from 'polished';

import { main } from 'styles/colors';

export const Container = styled.div`
  background-color: ${main.WHITE};
  padding: 16px;

  border-radius: 4px;

  box-shadow: 2px 2px 2px ${lighten(0.3, main.BROWNBAGE)};

  > div {
    width: 100%;
    margin-bottom: 10px;

    > h5 {
      text-transform: uppercase;
      display: flex;
      flex: 1;
      width: 100%;

      border-bottom: 1px solid ${main.GRAY};
    }
  }
`;
