import React from 'react';

import Header from 'components/page/Header';
import Sidemenu from 'components/page/Sidemenu';

import { Wrapper, Content } from './styles';

const Dashboard: React.FC = ({ children }) => {
  return (
    <Wrapper>
      <Header />
      <Sidemenu />

      <Content>{children}</Content>
    </Wrapper>
  );
};

export default Dashboard;
