import styled, { css } from 'styled-components';

import { main } from 'styles/colors';

import IButtonProperties from './interfaces/buttonTabProperties';

export const TabContainer = styled.div`
  width: 100%;
  display: flex;
  flex: 1;
  flex-direction: column;

  > header {
    overflow: hidden;
    border-bottom: none;
  }
`;

export const TabButton = styled.button<IButtonProperties>`
  cursor: pointer;
  outline: none;
  border: none;
  background-color: ${main.WHITE};

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: center;

  float: left;

  padding: 12px;
  text-align: center;
  transition: 0.3s;

  &:hover {
    color: ${main.GREEN};
  }

  ${(props) =>
    props.active &&
    css`
      border: 1px solid ${main.GRAY};
      border-top: 3px solid ${main.GREEN};
      border-bottom: none;
      color: ${main.GREEN};
    `}

  svg {
    margin-right: 5px;
  }
`;

export const ContentContainer = styled.main<IButtonProperties>`
  padding: 16px;
  border: 1px solid ${main.GRAY};

  ${(props) =>
    props.active
      ? css`
          display: block;
        `
      : css`
          display: none;
        `}
`;
