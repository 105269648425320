import React from 'react';
import { FiX } from 'react-icons/fi';

import { useModal } from 'hooks/modal';

import { main } from 'styles/colors';

import { Container } from './styles';

import IModalProperties from './interfaces/modalProperties';

const Modal: React.FC<IModalProperties> = ({ modal, isVisible }) => {
  const { onCloseModal } = useModal();

  return (
    <Container isVisible={isVisible}>
      <div>
        <header>
          <h4>{modal.title}</h4>

          <FiX size={24} color={main.WHITE} onClick={onCloseModal} />
        </header>
        <main>{modal.content}</main>
      </div>
    </Container>
  );
};

export default Modal;
