import React, { useState, useCallback } from 'react';
import { FiTrello, FiPackage, FiInfo } from 'react-icons/fi';

import Dashboard from 'pages/_layouts/dashboard';

import Card from 'components/utils/Card';

import { Container, TabContainer, TabButton } from './styles';

import SearchBox from './components/SearchBox';
import Info from './components/Info';
import Itens from './components/Itens';
import Montador from './components/Montador';

type Tabs = 'geral' | 'itens' | 'montador';

const Periodo: React.FC = () => {
  const [activeTab, setActiveTab] = useState<Tabs>('geral');

  const handleOpenTabContent = useCallback((tab: Tabs) => {
    setActiveTab(tab);
  }, []);

  return (
    <Dashboard>
      <Container>
        <h3>Ordem de Serviço &gt; Periodo</h3>

        <header>
          <Card title="buscar ordem de serviço">
            <SearchBox />
          </Card>
        </header>

        <section>
          <Card title="dados do contrato">
            <TabContainer>
              <header>
                <TabButton
                  type="button"
                  onClick={() => handleOpenTabContent('geral')}
                  active={activeTab === 'geral'}
                >
                  <FiTrello size={16} />
                  Geral
                </TabButton>
                <TabButton
                  type="button"
                  onClick={() => handleOpenTabContent('itens')}
                  active={activeTab === 'itens'}
                >
                  <FiPackage size={16} />
                  Itens
                </TabButton>
                <TabButton
                  type="button"
                  onClick={() => handleOpenTabContent('montador')}
                  active={activeTab === 'montador'}
                >
                  <FiInfo size={16} />
                  Montador
                </TabButton>
              </header>

              {activeTab === 'geral' && (
                <main>
                  <Info />
                </main>
              )}

              {activeTab === 'itens' && (
                <main>
                  <Itens />
                </main>
              )}

              {activeTab === 'montador' && (
                <main>
                  <Montador />
                </main>
              )}
            </TabContainer>
          </Card>
        </section>
      </Container>
    </Dashboard>
  );
};

export default Periodo;
