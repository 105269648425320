import React from 'react';

import { Container } from './styles';

import ILoaderProperties from './interfaces/loaderProperties';

const Loader: React.FC<ILoaderProperties> = ({ color }) => {
  return <Container color={color} />;
};

export default Loader;
