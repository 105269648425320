import styled, { css } from 'styled-components';

import { main, states } from 'styles/colors';

import Tooltip from 'components/utils/Tooltip';

import IAuthInputContainerProperties from './interfaces/authInputContainerProperties';

export const Container = styled.div<IAuthInputContainerProperties>`
  background: ${main.WHITE01};
  border-radius: 10px;
  padding: 12px;
  width: 100%;
  border: 2px solid ${main.GRAY};
  color: ${main.BROWNBAGE};
  display: flex;
  align-items: center;

  transition: 1s;

  & + div {
    margin-top: 8px;
  }

  ${(props) =>
    props.isErrored &&
    css`
      border-color: ${states.ERROR};
    `}

  ${(props) =>
    props.isFocused &&
    css`
      color: ${main.GREEN};
      border-color: ${main.GREEN};
    `}

  ${(props) =>
    props.isFilled &&
    css`
      color: ${main.GREEN};
    `}

  input {
    flex: 1;
    background:   transparent;
    border: 0;
    color: ${main.BROWN};

    &::placeholder {
      color:  ${main.BROWNBAGE};
    }
  }

  svg {
    margin-right: 16px;
  }
`;

export const Error = styled(Tooltip)`
  height: 20px;
  margin-left: 16px;

  svg {
    margin: 0;
  }

  span {
    background: ${states.ERROR};
    color: ${main.WHITE};

    &::before {
      border-color: ${states.ERROR} transparent;
    }
  }
`;
