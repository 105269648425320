import React, { useState, useCallback } from 'react';

import { format as dateFormat, isAfter } from 'date-fns';

import { FiSearch, FiCalendar } from 'react-icons/fi';

import { main, states } from 'styles/colors';

import { usePeriodo } from 'pages/Montagem/LancarOS/hooks/periodo';
import { useTabmenu } from 'pages/Montagem/LancarOS/hooks/tabmenu';
import { useAuth } from 'hooks/auth';
import IShop from 'hooks/auth/interfaces/shop';

import IconButton from 'components/buttons/IconButton';
import Select from 'components/inputs/Select';
import Datepicker from 'components/inputs/Datepicker';

const Periodo: React.FC = () => {
  const { user } = useAuth();

  const { getContracts } = usePeriodo();
  const { handleChangeActiveTab } = useTabmenu();

  const [shopCode, setShopCode] = useState<number>();
  const [shopCodeError, setShopCodeError] = useState('');

  const [startDate, setStartDate] = useState(new Date());
  const [startDateError, setStartDateError] = useState('');

  const [endDate, setEndDate] = useState(new Date());
  const [endDateError, setEndDateError] = useState('');

  const handleSearch = useCallback(async () => {
    if (!shopCode) {
      setShopCodeError('O codigo da loja é obrigatorio!');
    } else if (isAfter(startDate, endDate)) {
      setStartDateError('A data inicial deve ser menor que a final!');
      setEndDateError('A data final deve ser maior que a inicial!');
    } else {
      setStartDateError('');
      setEndDateError('');
      handleChangeActiveTab('Pedidos');
      getContracts({
        codloja: shopCode,
        initialDate: dateFormat(startDate, 'dd/MM/yyyy'),
        finalDate: dateFormat(endDate, 'dd/MM/yyyy'),
      });
    }
  }, [handleChangeActiveTab, getContracts, shopCode, startDate, endDate]);

  return (
    <>
      <h5>Período</h5>
      <section>
        <div>
          <Select
            label="Lojas"
            name="lojas"
            error={shopCodeError}
            onChange={(event) => {
              if (event.target.value === ' ') {
                return;
              }

              setShopCode(
                user.Lojas.filter(
                  (loja: IShop) => loja.CodLoja === Number(event.target.value),
                )[0].CodLoja,
              );
            }}
          >
            <option value=" "> </option>

            {user.Lojas.map((loja: IShop) => (
              <option key={String(loja.CodLoja)} value={loja.CodLoja}>
                {`${loja.CodLoja} - ${loja.Empresa}`}
              </option>
            ))}
          </Select>
        </div>

        <div>
          <Datepicker
            icon={FiCalendar}
            label="Data Inicial"
            color={main.GREEN}
            selected={startDate}
            onChange={(date: Date) => setStartDate(date)}
            error={startDateError}
          />
        </div>

        <div>
          <Datepicker
            icon={FiCalendar}
            label="Data Final"
            color={main.GREEN}
            selected={endDate}
            onChange={(date: Date) => setEndDate(date)}
            error={endDateError}
          />
        </div>

        <div>
          <IconButton
            color={states.SUCCEES}
            label="Buscar"
            icon={FiSearch}
            onClick={handleSearch}
          />
        </div>
        <div> </div>
      </section>
    </>
  );
};

export default Periodo;
