import React, { createContext, useCallback, useContext, useState } from 'react';

import IContext from 'hooks/modal/interfaces/context';
import IModal from 'hooks/modal/interfaces/modal';

import Modal from '../../components/Modal';

const ModalContext = createContext<IContext>({} as IContext);

export const ModalProvider: React.FC = ({ children }) => {
  const [modalVisibility, setModalVisibility] = useState(false);
  const [modal, setModal] = useState<IModal>({} as IModal);

  const onShowModal = useCallback(({ title, content }: IModal) => {
    if (content !== undefined && title !== null) {
      setModal({ title, content });
    }

    setModalVisibility(true);
  }, []);

  const onCloseModal = useCallback(() => {
    setModal({} as IModal);
    setModalVisibility(false);
  }, []);

  return (
    <ModalContext.Provider value={{ onShowModal, onCloseModal }}>
      {children}
      <Modal modal={modal} isVisible={modalVisibility} />
    </ModalContext.Provider>
  );
};

export function useModal(): IContext {
  const context = useContext(ModalContext);

  if (!context) {
    throw new Error('useModal must be used within a ModalProvider');
  }

  return context;
}
