import React, { useState, useCallback } from 'react';

import Input from 'components/inputs/Input';
import Checkbox from 'components/inputs/Checkbox';

import { useContrato } from 'pages/Montagem/LancarOS/hooks/contrato';
import IContractItem from 'pages/Montagem/LancarOS/hooks/contrato/interfaces/contractItem';

interface IItemRowProps {
  item: IContractItem;
}

const InnerItemRow: React.FC<IItemRowProps> = ({ item }) => {
  const { handleItem } = useContrato();

  const [mountingAmountError, setMountingAmountError] = useState('');

  const [isAssemblyOutOfTown, setIsAssemblyOutOfTown] = useState(
    item.motagemforadacidade,
  );

  const handleMountingAmountItem = useCallback(
    (mountingamount: number) => {
      if (mountingamount > item.saldomotagem || mountingamount === 0) {
        setMountingAmountError('Insira uma quantidade valída!');
        return;
      }

      setMountingAmountError('');
      handleItem({
        itemCode: item.CodProd,
        mountingAmount: mountingamount,
      });
    },
    [handleItem, item],
  );

  const handleIsAssemblyOutOfTownItem = useCallback(
    (isassemblyoutoftown: boolean) => {
      handleItem({
        itemCode: item.CodProd,
        isAssemblyOutOfTown: isassemblyoutoftown,
      });
    },
    [handleItem, item],
  );

  return (
    <tr>
      <td>{item.NumOrc}</td>
      <td>{item.Item}</td>
      <td>{item.CodProd}</td>
      <td>{item.Descricao}</td>
      <td>{item.qtdpedido}</td>
      <td>{item.qtdmontada}</td>
      <td>{item.saldomotagem}</td>
      <td>
        <Input
          name={item.CodProd}
          type="number"
          inputMode="numeric"
          placeholder="add. quantidade"
          error={mountingAmountError}
          onChange={async (event) => {
            handleMountingAmountItem(Number(event.target.value));
          }}
        />
      </td>
      <td>{item.comissaomontador}</td>
      <td>{item.valortotal}</td>
      <td>
        <Checkbox
          label=" "
          name={String(item.NumOrc)}
          checked={isAssemblyOutOfTown}
          onChange={() => {
            if (isAssemblyOutOfTown) {
              setIsAssemblyOutOfTown(false);
              handleIsAssemblyOutOfTownItem(false);
            } else {
              setIsAssemblyOutOfTown(true);
              handleIsAssemblyOutOfTownItem(true);
            }
          }}
        />
      </td>
    </tr>
  );
};

export default InnerItemRow;
