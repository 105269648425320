import React from 'react';

import { BrowserCodeProvider } from './browsercode';
import { ModalProvider } from './modal';
import { AuthProvider } from './auth';
import { ToastProvider } from './toast';

const AppProvider: React.FC = ({ children }) => (
  <ToastProvider>
    <BrowserCodeProvider>
      <ModalProvider>
        <AuthProvider>{children}</AuthProvider>
      </ModalProvider>
    </BrowserCodeProvider>
  </ToastProvider>
);

export default AppProvider;
