import React, { createContext, useCallback, useContext, useState } from 'react';
import { uuid } from 'uuidv4';

import ToastContainer from 'components/utils/ToastContainer';

import IContext from './interfaces/context';
import IToast from './interfaces/toast';

const ToastContext = createContext<IContext>({} as IContext);

const ToastProvider: React.FC = ({ children }) => {
  const [messages, setMessages] = useState<IToast[]>([]);

  const addToast = useCallback(
    ({ type, title, description }: Omit<IToast, 'id'>) => {
      const id = uuid();

      const toast = {
        id,
        type,
        title,
        description,
      };

      setMessages((oldState) => [...oldState, toast]);
    },
    [],
  );

  const removeToast = useCallback((id: string) => {
    setMessages((state) => state.filter((message) => message.id !== id));
  }, []);

  return (
    <ToastContext.Provider value={{ addToast, removeToast }}>
      {children}
      <ToastContainer messages={messages} />
    </ToastContext.Provider>
  );
};

function useToast(): IContext {
  const context = useContext(ToastContext);

  if (!context) {
    throw new Error('useToast must be used within a ToastProvider');
  }

  return context;
}

export { ToastProvider, useToast };
