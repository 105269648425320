import React, { useState } from 'react';
import { FiSearch, FiChevronRight } from 'react-icons/fi';

import { states } from 'styles/colors';

import { Container, ChooseSection } from './styles';

import ChooseButton from '../ChooseButton';

import Avulsos from './components/InnerFormAvulsos';
import Exposicao from './components/InnerFormExposicao';
import Outros from './components/InnerFormOutros';

const FormOutros: React.FC = () => {
  const [currentForm, setCurrentForm] = useState(0);

  return (
    <Container>
      <ChooseSection>
        <ChooseButton
          isSelected={currentForm === 0}
          onClick={() => setCurrentForm(0)}
          color={states.SELECTED}
          label="Avulsos"
          icon={currentForm === 0 ? FiSearch : FiChevronRight}
        />

        <ChooseButton
          isSelected={currentForm === 1}
          onClick={() => setCurrentForm(1)}
          color={states.SELECTED}
          label="Exposição"
          icon={currentForm === 1 ? FiSearch : FiChevronRight}
        />

        <ChooseButton
          isSelected={currentForm === 2}
          onClick={() => setCurrentForm(2)}
          color={states.SELECTED}
          label="Outros"
          icon={currentForm === 2 ? FiSearch : FiChevronRight}
        />
      </ChooseSection>

      <main>
        {currentForm === 0 && <Avulsos />}

        {currentForm === 1 && <Exposicao />}

        {currentForm === 2 && <Outros />}
      </main>
    </Container>
  );
};

export default FormOutros;
