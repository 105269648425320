import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  margin-top: 8px;

  > section {
    margin: 8px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 8px;
  }

  > div {
    display: flex;
    flex: 1;

    align-items: center;
    justify-content: center;

    padding-left: 8px;
    padding-right: 8px;
  }
`;
