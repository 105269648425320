import React, { createContext, useCallback, useContext, useState } from 'react';

import IContext from './interfaces/context';

const TabMenuContext = createContext<IContext>({} as IContext);

export const TabMenuProvider: React.FC = ({ children }) => {
  const [data, setData] = useState('');

  const handleChangeActiveTab = useCallback((tabTitle: string) => {
    setData(tabTitle);
  }, []);

  return (
    <TabMenuContext.Provider value={{ activeTab: data, handleChangeActiveTab }}>
      {children}
    </TabMenuContext.Provider>
  );
};

export function useTabmenu(): IContext {
  const context = useContext(TabMenuContext);

  if (!context) {
    throw new Error('useTabmenu must be used within a TabMenuProvider');
  }

  return context;
}
