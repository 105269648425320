import React, { useState, useCallback, useEffect } from 'react';

import { FiChevronLeft, FiChevronRight } from 'react-icons/fi';
import Button from 'components/buttons/Button';
import IconButton from 'components/buttons/IconButton';

import { main } from 'styles/colors';

import { usePeriodo } from 'pages/Montagem/LancarOS/hooks/periodo';

import { Table } from './styles';

import TableRow from '../TableRow';

const BaseTable: React.FC = () => {
  const { contratList } = usePeriodo();

  const [page, setPage] = useState(0);
  const [totalPages, setTotalPages] = useState<number[]>([]);
  const [pageSize] = useState(11);

  useEffect(() => {
    function countPages(): void {
      if (!contratList) return;

      const temporaryArray: number[] = [];

      for (
        let index = 0;
        index <= Math.floor(contratList.length / pageSize);
        index++
      ) {
        temporaryArray.push(index);
      }

      setTotalPages(temporaryArray);
    }

    countPages();
  }, [contratList, pageSize]);

  const handlePreviousPage = useCallback(
    (_event) => {
      setPage(() => {
        return page > 0 ? page - 1 : 0;
      });
    },
    [page],
  );

  const handleNextPage = useCallback(
    (_event) => {
      setPage(() => {
        return page < Math.floor(contratList.length / pageSize)
          ? page + 1
          : page;
      });
    },
    [page, contratList, pageSize],
  );

  return (
    <>
      <Table>
        <caption>Pedios buscados</caption>
        <thead>
          <tr>
            <th>CodLoja</th>
            <th>NumOrc</th>
            <th>CodCli</th>
            <th>Cliente</th>
            <th>data</th>
            <th>Funcionario</th>
            <th>Cidade</th>
            <th>UF</th>
            <th> </th>
          </tr>
        </thead>
        <tbody>
          {contratList.length > 0 &&
            contratList
              .slice(page * pageSize, page * pageSize + pageSize)
              .map((order) => (
                <TableRow
                  key={String(order.NumOrc)}
                  order={order}
                  CodLoja={order.CodLoja}
                  NumOrc={order.NumOrc}
                  CodCli={order.CodCli}
                  NomeCliente={order.NomeCliente}
                  data={order.data}
                  NomeFuncionario={order.NomeFuncionario}
                  Cidade={order.Cidade}
                  UF={order.UF}
                />
              ))}
        </tbody>
        <tfoot>
          <tr>
            <td>
              <IconButton
                onClick={(event) => handlePreviousPage(event)}
                color={main.BROWN}
                icon={FiChevronLeft}
                iconSize={15}
              />
            </td>
            <td colSpan={7}>
              <div>
                {totalPages.map((value) => (
                  <Button
                    key={String(value)}
                    color={main.GREEN}
                    onClick={() => setPage(value)}
                  >
                    {value}
                  </Button>
                ))}
              </div>
            </td>
            <td>
              <IconButton
                onClick={(event) => handleNextPage(event)}
                color={main.BROWN}
                icon={FiChevronRight}
                iconSize={15}
              />
            </td>
          </tr>
        </tfoot>
      </Table>
    </>
  );
};

export default BaseTable;
