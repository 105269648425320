import styled, { css } from 'styled-components';
import Tooltip from 'components/utils/Tooltip';

import { main, states } from 'styles/colors';

import IStyledContainerProperties from '../_shared/interfaces/styledContainerProperties';

export const Field = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  > span {
    font-size: 16px;
  }
`;

export const Container = styled.div<IStyledContainerProperties>`
  background-color: ${(props) => props.color};
  padding: 4px;
  width: 100%;
  border: 1px solid ${main.GRAY};
  border-radius: 4px;
  color: ${main.BROWNBAGE};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  ${(props) =>
    props.isErrored &&
    css`
      border: 1px solid ${states.ERROR};
      background: ${main.GRAY};
    `}

  ${(props) =>
    props.isFocused &&
    css`
      border-color: ${main.BLACK};
    `}

  ${(props) =>
    props.isFilled &&
    css`
      color: ${states.SELECTED};
    `}


    ${(props) =>
      props.isDisabled &&
      css`
        background-color: ${main.FADEBLACK};
      `}

  input {
    background: transparent;
    width: 90%;
    text-align: center;
    font-weight: bold;
    color: ${main.WHITE};
    border: 0;
    font-size: 14px;
    cursor: pointer;

    &::placeholder {
      color:  ${main.WHITE};
      font-size: 12px;
    }
  }

  > svg {
    margin-right: 5px;
    color:  ${main.WHITE};
  }

`;

export const Error = styled(Tooltip)`
  height: 20px;

  svg {
    margin: 0;
    margin-right: 8px;
  }

  span {
    background: ${states.ERROR};
    color: ${main.WHITE};

    &::before {
      border-color: ${states.ERROR} transparent;
    }
  }
`;
