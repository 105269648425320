import React, { useState } from 'react';
import { FiPlusCircle } from 'react-icons/fi';

import { useAuth } from 'hooks/auth';
import IShop from 'hooks/auth/interfaces/shop';

import IconButton from 'components/buttons/IconButton';
import Select from 'components/inputs/Select';
// import Input from 'components/inputs/Input';

import { states } from 'styles/colors';

const Outros: React.FC = () => {
  const { user } = useAuth();

  const [shopCode, setShopCode] = useState<number>();
  const [shopCodeError, setShopCodeError] = useState('');

  return (
    <>
      <h5>Outros</h5>

      <section>
        <div>
          <Select
            label="Lojas"
            name="lojas"
            error={shopCodeError}
            onChange={(event) => {
              if (event.target.value === ' ') {
                return;
              }

              setShopCode(
                user.Lojas.filter(
                  (loja: IShop) => loja.CodLoja === Number(event.target.value),
                )[0].CodLoja,
              );
            }}
          >
            <option value=" "> </option>

            {user.Lojas.map((loja: IShop) => (
              <option key={String(loja.CodLoja)} value={loja.CodLoja}>
                {`${loja.CodLoja} - ${loja.Empresa}`}
              </option>
            ))}
          </Select>
        </div>

        {/* <div> <Input name="tipocomissao" label="Tipo de Comissão" /> </div> */}

        <div>
          <IconButton
            color={states.SUCCEES}
            label="Adicionar"
            icon={FiPlusCircle}
          />
        </div>
      </section>
    </>
  );
};

export default Outros;
