import React from 'react';

import { Container } from './styles';

import ICardProperties from './interfaces/cardProperties';

const Card: React.FC<ICardProperties> = ({ title, children, ...rest }) => {
  return (
    <Container {...rest}>
      {title && (
        <div>
          <h5>{title}</h5>
        </div>
      )}
      <>{children}</>
    </Container>
  );
};

export default Card;
