import React from 'react';

import { format as dateFormat, parseISO } from 'date-fns';
import { FiEye } from 'react-icons/fi';
import IconButton from 'components/buttons/IconButton';

import { main } from 'styles/colors';

import { useContrato } from 'pages/Montagem/LancarOS/hooks/contrato';

import { useTabmenu } from 'pages/Montagem/LancarOS/hooks/tabmenu';

import ITableRowProperties from './interfaces/tableRowProperties';

const TableRow: React.FC<ITableRowProperties> = ({
  order,
  CodLoja,
  NumOrc,
  CodCli,
  NomeCliente,
  data,
  NomeFuncionario,
  Cidade,
  UF,
}) => {
  const { getContract } = useContrato();
  const { handleChangeActiveTab } = useTabmenu();

  return (
    <tr>
      <td>{CodLoja}</td>
      <td>{NumOrc}</td>
      <td>{CodCli}</td>
      <td>{NomeCliente}</td>
      <td>{dateFormat(parseISO(data), 'dd/MM/yyyy')}</td>
      <td>{NomeFuncionario}</td>
      <td>{Cidade}</td>
      <td>{UF}</td>
      <td>
        <IconButton
          color={main.GREEN}
          icon={FiEye}
          iconSize={15}
          onClick={() => {
            getContract({
              shopCode: order.CodLoja,
              orderCode: order.NumOrc,
            });
            handleChangeActiveTab('Geral');
          }}
        />
      </td>
    </tr>
  );
};

export default TableRow;
