import styled from 'styled-components';
import { shade } from 'polished';

import { main } from 'styles/colors';

import IStyledContainerProperties from '../_shared/interfaces/styledContainerProperties';

export const Container = styled.button<IStyledContainerProperties>`
  border-radius: 4px;
  border: 0;
  padding: 8px;
  color: ${main.WHITE};
  width: 100%;
  font-weight: 700;
  font-size: 12px;
  transition: background-color 0.2s;

  background-color: ${(props) => props.color};

  &:hover {
    background: ${(props) => props.color && shade(0.2, props.color)};
  }
`;
