import styled, { css } from 'styled-components';
import { lighten } from 'polished';
import { main } from 'styles/colors';

import background from 'assets/images/backgrounds/login/background-1920x930.jpg';

import appearFromRightSide from 'functions/styles/appearFromRightSide';

import IAuthCurrentSelectedForm from './interfaces/authCurrentSelectedForm';

export const Container = styled.div`
  height: 100vh;
  display: flex;
  align-items: stretch;

  > img {
    width: 60px;
    position: absolute;
    bottom: 8px;
    right: 8px;
  }
`;

export const Content = styled.div`
  background-color: ${lighten(0.1, main.WHITE)};

  display: flex;

  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 35%;
  max-width: 700px;
`;

export const AnimationContainer = styled.div`
  display: flex;

  flex-direction: column;
  align-items: center;
  justify-content: center;

  animation: ${appearFromRightSide} 1s;

  > header {
    width: 100%;
    display: flex;
    flex-direction: column;

    > h3 {
      color: ${main.GREEN};
      font-weight: bold;
      text-transform: uppercase;
    }

    img {
      height: 95px;
      margin-bottom: 5px;
    }
  }

  > section {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    width: 100%;

    > form {
      display: flex;
      flex: 1;
      flex-direction: column;
      width: 100%;
    }
  }
`;

export const AuthType = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  width: 100%;

  margin-top: 12px;
  margin-bottom: 12px;

  font-size: 12px;
  font-weight: bold;

  > strong {
    font-weight: bold;
    margin-right: 4px;
    color: ${main.GREEN};
  }
`;

export const AuthOption = styled.span<IAuthCurrentSelectedForm>`
  cursor: pointer;

  margin-right: 8px;

  text-transform: uppercase;
  font-size: 9px;

  border: 1px solid ${main.GREEN};
  color: ${main.GREEN};

  padding: 5px;

  border-radius: 5px;

  ${(props) =>
    props.isSelected &&
    css`
      background-color: ${main.GREEN};
      color: ${main.WHITE};

      transition: background 0.3s;
      transition: color 0.5s;
    `}
`;

export const Background = styled.div`
  flex: 1;
  background: url(${background}) no-repeat center;
  background-size: cover;
`;
