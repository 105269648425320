import React from 'react';

import { Container } from './styles';

import ITableProperties from './interfaces/tableProperties';

const Table: React.FC<ITableProperties> = ({
  height,
  caption,
  sections,
  children,
  footer: Footer,
}) => {
  return (
    <Container height={height}>
      <table>
        <caption>{caption}</caption>
        <thead>
          <tr>
            {sections.map((section) => (
              <th key={String(section)}>{section}</th>
            ))}
          </tr>
        </thead>
        <tbody>{children}</tbody>
        {Footer && <Footer />}
      </table>
    </Container>
  );
};

export default Table;
