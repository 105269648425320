import { createGlobalStyle } from 'styled-components';
import { main } from './colors';

export default createGlobalStyle`
  *{
    margin:0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
  }

  body {
    background: ${main.WHITE};
    color: ${main.BLACK};
    -webkit-font-smoothing: antialiased;
  }

  body, input, button {
    font-family: 'Baloo Tamma 2', cursive;
    font-size: 16px;
  }

  h1, h2, h3, h4, h5, h6, strong {
    font-weight: 500;
  }

  button {
    cursor: pointer;
  }

  /* square buttons */
.rec.rec-arrow {
    border-radius: 50%;
    background-color: rgb(0 125 139);
}
/* round buttons on hover */
.rec.rec-arrow:hover {
    border-radius: 60%;
    background-color: rgb(0 125 139);
}
/* hide disabled buttons */
.rec.rec-arrow:disabled {
    visibility: hidden;
}
/* disable default outline on focused items */
/* add custom outline on focused items */
.rec-carousel-item:focus {
    outline: none;
    box-shadow: inset 0 0 1px 1px lightgrey;
    background-color: rgb(0 125 139);
}

.rec-item-wrapper {
  width: 100%;
}
`;
