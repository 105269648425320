import styled from 'styled-components';
import { shade, lighten } from 'polished';

import { main } from 'styles/colors';

export const Container = styled.div`
  z-index: 1000;
  background-color: ${main.WHITE};

  box-sizing: border-box;

  position: absolute;
  top: 8.5vh;
  left: 0;

  width: 200px;
  height: calc(100vh - 8.5vh);

  box-shadow: 0 2px 3px ${shade(0.3, main.BLACK)};

  > main {
    width: 100%;
    height: calc(100% - 60px);
    flex: 1;
    overflow-y: scroll;

    scrollbar-color: ${main.GREEN} ${main.WHITE};
    scrollbar-width: thin;

    &::-webkit-scrollbar {
      width: 6px;
      position: relative;
    }
    &::-webkit-scrollbar-track {
      background-color: ${main.WHITE};
    }
    &::-webkit-scrollbar-thumb {
      background-color: ${main.GREEN};
    }
    &::-webkit-scrollbar-thumb:hover {
      background-color: ${lighten(0.05, main.GREEN)};
    }
  }
`;

export const SectionTitle = styled.span`
  color: ${main.GRAY};
  margin-left: 16px;

  text-transform: uppercase;
  font-weight: bold;
  font-size: 10px;
`;

export const User = styled.div`
  width: 100%;
  height: 60px;

  display: flex;
  flex-direction: row;

  padding-left: 8px;
  padding-right: 8px;

  align-items: center;
  justify-content: space-around;

  background: ${main.GREEN};
  border-bottom-right-radius: 16px;

  > section {
    margin: 0;
    padding: 0;

    display: flex;
    align-items: center;
    justify-content: space-around;

    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: ${main.WHITE};
    color: ${main.GREEN};
  }

  > div {
    height: 40px;

    display: flex;
    flex-direction: column;
    flex: 1;

    padding: 5px;

    align-items: flex-start;
    justify-content: center;

    color: ${main.WHITE};

    > strong {
      font-size: 15px;
    }

    > span {
      font-weight: 100;
      font-size: 12px;
    }
  }
`;
