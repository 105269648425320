import styled from 'styled-components';
import { lighten } from 'polished';

import { main } from 'styles/colors';

export const Container = styled.tr`
  > td {
    > div {
      max-width: 120px;
    }

    > section {
      min-width: 350px;
      display: flex;
      flex-direction: row;
      align-items: flex-end;

      > div {
        width: 110px;
        margin-right: 5px;
      }

      > section {
        width: 140px;
      }

      > main {
        display: flex;
        flex: 1;
        flex-direction: row;
        margin-right: 5px;

        > button {
          margin-left: 2px;
          width: 30px;
          height: 30px;
        }
      }

      > ul {
        height: 60px;
        display: flex;
        flex: 1;
        flex-direction: column;

        > li {
          display: flex;
          flex: 1;
          flex-direction: row;
          align-items: center;
          justify-content: flex-start;
          border-bottom: 1px solid ${main.LIGHTGRAY};

          > button {
            margin-right: 5px;
            width: 21px;
            height: 21px;
          }
        }

        overflow-x: hidden;
        overflow-y: auto;
        scrollbar-color: ${main.GRAY} ${main.LIGHTGRAY};
        scrollbar-width: thin;

        &::-webkit-scrollbar {
          width: 6px;
          height: 6px;
          position: relative;
        }

        &::-webkit-scrollbar-track {
          background-color: ${main.LIGHTGRAY};
        }

        &::-webkit-scrollbar-thumb {
          background-color: ${main.GRAY};
        }

        &::-webkit-scrollbar-thumb:hover {
          background-color: ${lighten(0.05, main.GREEN)};
        }
      }
    }
  }
`;
