import styled from 'styled-components';
import { darken } from 'polished';

import { main } from 'styles/colors';

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  > button {
    padding: 0;
    margin: 0;
    border: 0;

    display: flex;
    align-items: center;
    justify-content: center;

    width: 30px;
    height: 30px;

    border-radius: 4px;

    background-color: ${darken(0.12, main.GREEN)};

    > svg {
      padding: 0;
      margin: 0;
    }
  }

  > select {
    padding: 0;
    margin: 0;
    border: 0;

    height: 30px;
  }
`;
