import React, { useState, useRef, useCallback } from 'react';

import { FiAlertCircle } from 'react-icons/fi';

import { Field, Container, Error } from './styles';

import ISelectProperties from './interfaces/selectProperties';

const Select: React.FC<ISelectProperties> = ({
  label,
  error,
  icon: Icon,
  children,
  ...rest
}) => {
  const selectRef = useRef<HTMLSelectElement>(null);

  const [isFocused, setIsFocused] = useState(false);
  const [isFilled, setIsFilled] = useState(false);

  const handleInputFocus = useCallback(() => {
    setIsFocused(true);
  }, []);

  const handleInputBlur = useCallback(() => {
    setIsFocused(false);

    setIsFilled(!!selectRef.current?.value);
  }, []);

  return (
    <Field>
      {label && <span>{label}</span>}
      <Container isErrored={!!error} isFilled={isFilled} isFocused={isFocused}>
        {Icon && <Icon size={20} />}
        <select
          onFocus={handleInputFocus}
          onBlur={handleInputBlur}
          ref={selectRef}
          {...rest}
        >
          {children}
        </select>

        {error && (
          <Error title={error}>
            <FiAlertCircle color="#c53030" size={13} />
          </Error>
        )}
      </Container>
    </Field>
  );
};

export default Select;
