import styled, { css } from 'styled-components';

import { main, states } from 'styles/colors';

import Tooltip from 'components/utils/Tooltip';

import IStyledContainerProperties from '../_shared/interfaces/styledContainerProperties';

export const Field = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  > span {
    font-size: 16px;
  }
`;

export const Container = styled.div<IStyledContainerProperties>`
  background: ${main.WHITE};
  border-radius: 2px;
  padding: 4px;
  width: 100%;
  border: 1px solid ${main.BLACK};
  color: ${main.BROWNBAGE};
  display: flex;
  align-items: center;
  justify-content: center;

  ${(props) =>
    props.isErrored &&
    css`
      border-color: ${states.ERROR};
    `}

  ${(props) =>
    props.isFocused &&
    css`
      color: ${states.SELECTED};
      border-color: ${states.SELECTED};
    `}

  ${(props) =>
    props.isFilled &&
    css`
      color: ${states.SELECTED};
    `}

    ${(props) =>
      props.isDisabled &&
      css`
        background-color: ${main.FADEBLACK};
      `}

  textarea {
    display: flex;
    flex: 1;
    height: 100px;
    background: transparent;
    border: 0;
    color: ${main.BROWN};
    font-size: 14px;

    &::placeholder {
      color:  ${main.BROWNBAGE};
      font-size: 12px;
    }
  }

  svg {
    margin-right: 5px;
  }
`;

export const Error = styled(Tooltip)`
  height: 20px;
  margin-left: 5px;

  svg {
    margin: 0;
  }

  span {
    background: ${states.ERROR};
    color: ${main.WHITE};

    &::before {
      border-color: ${states.ERROR} transparent;
    }
  }
`;
