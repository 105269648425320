import styled from 'styled-components';
import { shade } from 'polished';

import { main } from 'styles/colors';

import IStyledContainerProperties from '../_shared/interfaces/styledContainerProperties';

export const Container = styled.button<IStyledContainerProperties>`
  border: 0;

  width: 100%;
  padding: 8px;
  border-radius: 4px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  background-color: ${(props) => props.color};
  color: ${main.WHITE};

  font-weight: 700;
  font-size: 12px;

  transition: background-color 0.2s;

  > span {
    display: flex;
    flex: 3;
    align-items: center;
    justify-content: center;
  }

  > div {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
  }

  &:hover {
    background-color: ${(props) => shade(0.2, props.color)};
  }
`;
