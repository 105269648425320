import styled, { css } from 'styled-components';

import { shade } from 'polished';
import { animated } from 'react-spring';

import { main } from 'styles/colors';

import IStyledToastProperties from '../../interfaces/styledToastProperties';

import toastVariationsStyles from '../../utils/toastVariationsStyles';

export const Container = styled(animated.div)<IStyledToastProperties>`
  width: 300px;
  position: relative;
  padding: 16px 30px 16px 16px;
  border-radius: 10px;
  box-shadow: 2px 2px 8px ${shade(0.2, main.BLACK)};
  display: flex;
  & + div {
    margin-top: 8px;
  }
  ${(props) => toastVariationsStyles[props.type || 'info']}
  > svg {
    margin: 4px 12px 0 0;
  }
  div {
    flex: 1;
    p {
      margin-top: 4px;
      font-size: 14px;
      opacity: 0.8;
      line-height: 20px;
    }
  }
  button {
    position: absolute;
    right: 16px;
    top: 19px;
    opacity: 0.6;
    border: 0;
    background: transparent;
    color: inherit;
  }
  ${(props) =>
    !props.hasDescription &&
    css`
      align-items: center;
      svg {
        margin-top: 0;
      }
    `}
`;
