import React, { useCallback, FormEvent, useState } from 'react';
import md5 from 'md5';
import utf8 from 'utf8';

import { useHistory } from 'react-router-dom';

import { FiBarChart2, FiUser, FiLock, FiKey } from 'react-icons/fi';

import * as Yup from 'yup';

import { useBrowserCode } from 'hooks/browsercode';
import { useAuth } from 'hooks/auth';
import { useToast } from 'hooks/toast';

import ISignInError from 'hooks/auth/interfaces/signInError';

import getValidationErrors from 'utils/getValidationErrors';

import DotsLoader from 'components/utils/DotsLoader';
import Input from '../components/AuthInput';
import Button from '../components/AuthButton';

const FormCnpj: React.FC = () => {
  const { browserCode } = useBrowserCode();
  const { signIn, loading } = useAuth();
  const { addToast } = useToast();

  const history = useHistory();

  const [cnpj, setCnpj] = useState('');
  const [cnpjError, setCnpjError] = useState('');

  const [user, setUser] = useState('');
  const [userError, setUserError] = useState('');

  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');

  const [authCode, setAuthCode] = useState(false);
  const [authenticationCode, setAuthenticationCode] = useState('');
  const [authenticationCodeError, setAuthenticationCodeError] = useState('');

  const codeRequired = useCallback(
    (message) => {
      addToast({
        type: 'error',
        title: 'Ops!, Algo Aconteceu..',
        description: message,
      });
    },
    [addToast],
  );

  const handleSignIn = useCallback(
    async (event: FormEvent<HTMLFormElement>, reSendAuthCode?: boolean) => {
      try {
        event.preventDefault();

        const schema = Yup.object().shape({
          cnpj: Yup.string().required('CNPJ obrigatório'),
          user: Yup.string().required('Usuario obrigatório'),
          password: Yup.string().required('Senha obrigatória'),
        });

        await schema.validate(
          { cnpj, user, password },
          {
            abortEarly: false,
          },
        );

        const response = await signIn({
          type: 'cnpj',
          cnpj,
          user: user.toUpperCase(),
          password: md5(password.toUpperCase()).toUpperCase(),
          authorizationCode: browserCode,
          AuthenticationCode: authenticationCode.toUpperCase(),
          resendcode: reSendAuthCode,
        });

        if (response === true) {
          history.push('/montagem/lancaros');
          return;
        }

        const { code, message } = response as ISignInError;

        switch (code) {
          case 1:
            setAuthCode(true);
            setAuthenticationCodeError('Codigo obrigatorio!');
            codeRequired(utf8.decode(message));
            break;

          case 0:
            codeRequired(utf8.decode(message));
            break;

          default:
            codeRequired(utf8.decode(message));
            break;
        }
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);
          if (errors.cnpj) {
            setCnpjError(errors.cnpj);
          }

          if (errors.user) {
            setUserError(errors.user);
          }

          if (errors.password) {
            setPasswordError(errors.password);
          }

          addToast({
            type: 'error',
            title: 'Erro na autenticação',
            description:
              'Ocorreu um erro ao fazer login, cheque as credenciasis.',
          });
          return;
        }

        addToast({
          type: 'error',
          title: 'Erro na autenticação',
          description:
            'Ocorreu um erro ao fazer login, cheque as credenciasis.',
        });
      }
    },
    [
      signIn,
      cnpj,
      user,
      password,
      authenticationCode,
      browserCode,
      history,
      codeRequired,
      addToast,
    ],
  );

  const handleReSend = useCallback(
    (event) => {
      handleSignIn(event, true);
    },
    [handleSignIn],
  );

  return (
    <>
      <form method="post" onSubmit={handleSignIn}>
        <Input
          icon={FiBarChart2}
          name="cnpj"
          type="text"
          placeholder="CNPJ"
          onChange={(event) => setCnpj(event.target.value)}
          error={cnpjError}
        />

        <Input
          icon={FiUser}
          name="user"
          type="text"
          placeholder="Usuário"
          onChange={(event) => setUser(event.target.value)}
          error={userError}
        />

        <Input
          icon={FiLock}
          type="password"
          name="password"
          placeholder="Senha"
          onChange={(event) => setPassword(event.target.value)}
          error={passwordError}
        />

        {authCode && (
          <Input
            icon={FiKey}
            type="text"
            name="authCode"
            placeholder="Codigo de Autorização"
            onChange={(event) => setAuthenticationCode(event.target.value)}
            error={authenticationCodeError}
          />
        )}

        {loading ? (
          <Button type="button" disabled>
            <DotsLoader />
          </Button>
        ) : (
          <Button type="submit">Entrar</Button>
        )}

        {authCode && (
          <>
            {loading ? (
              <div
                style={{
                  marginTop: 15,
                }}
              >
                <Button type="button" disabled>
                  <DotsLoader />
                </Button>
              </div>
            ) : (
              <div
                style={{
                  marginTop: 15,
                }}
              >
                <Button type="submit" onClick={handleReSend}>
                  Re-enviar codigo
                </Button>
              </div>
            )}
          </>
        )}
      </form>
    </>
  );
};

export default FormCnpj;
