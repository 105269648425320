import React, { useState } from 'react';
import { FiSearch, FiChevronRight } from 'react-icons/fi';

import { states } from 'styles/colors';

import ChooseButton from '../ChooseButton';

import { Container, ChooseSection } from './styles';

import FormPeriodo from './components/InnerFormPeriodo';
import FormCarga from './components/InnerFormCarga';

const Emlote: React.FC = () => {
  const [currentForm, setCurrentForm] = useState(0);

  return (
    <Container>
      <ChooseSection>
        <ChooseButton
          isSelected={currentForm === 0}
          onClick={() => setCurrentForm(0)}
          color={states.SELECTED}
          label="Periodo"
          icon={currentForm === 0 ? FiSearch : FiChevronRight}
        />

        <ChooseButton
          isSelected={currentForm === 1}
          onClick={() => setCurrentForm(1)}
          color={states.SELECTED}
          label="Carga"
          icon={currentForm === 1 ? FiSearch : FiChevronRight}
        />
      </ChooseSection>

      <main>
        {currentForm === 0 && <FormPeriodo />}

        {currentForm === 1 && <FormCarga />}
      </main>
    </Container>
  );
};

export default Emlote;
