import styled, { css } from 'styled-components';
import { shade } from 'polished';

import { main } from 'styles/colors';

import IContainerProperties from './interfaces/containerProperties';

export const Container = styled.button<IContainerProperties>`
  border: 0;

  width: 100%;
  padding: 8px;
  border-radius: 4px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  border: 1px solid ${main.GREEN};

  font-weight: 700;
  font-size: 12px;

  transition: 0.5s;

  ${(props) =>
    props.isSelected
      ? css`
          background-color: ${main.GREEN};
          color: ${main.WHITE};
          svg {
            color: ${main.WHITE};
          }
        `
      : css`
          background-color: ${main.WHITE};
          color: ${main.GREEN};
          svg {
            color: ${main.GREEN};
          }
        `};

  > span {
    display: flex;
    flex: 3;
    align-items: center;
    justify-content: center;
  }

  > div {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
  }

  &:hover {
    background-color: ${shade(0.2, main.GREEN)};
    color: ${main.WHITE};
    svg {
      color: ${main.WHITE};
    }
  }
`;
