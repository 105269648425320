import styled from 'styled-components';

import { main } from 'styles/colors';

import IItemContainerProperties from '../../interfaces/itemContainerProperties';

export const Container = styled.div<IItemContainerProperties>`
  width: 100%;
  display: felx;
  flex-direction: row;
  align-items: center;

  > a {
    text-decoration: none;

    cursor: pointer;
    color: ${(props) => props.color};

    width: 100%;
    padding: 6px;

    text-align: left;
    border: none;
    outline: none;
    transition: 0.4s;

    font-size: 12px;

    display: flex;
    flex-direction: row;

    align-items: center;

    &:hover {
      color: ${main.GREEN};
    }

    > svg {
      margin-right: 8px;
    }

    > p {
      text-transform: capitalize;
    }
  }
`;
