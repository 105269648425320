import React, { useCallback } from 'react';
import { BiSend } from 'react-icons/bi';
import { FiX } from 'react-icons/fi';

import { useToast } from 'hooks/toast';
import { useModal } from 'pages/Montagem/LancarOS/hooks/modal';
import { useServiceorder } from 'pages/Montagem/LancarOS/hooks/serviceorder';

import IconButton from 'components/buttons/IconButton';

import { main, states } from 'styles/colors';

import { Container, Table } from './styles';

import InnerTableRow from '../InnerTableRow';

const InnerEachItemFrom: React.FC = () => {
  const { onCloseModal } = useModal();
  const { addToast } = useToast();
  const {
    serviceOrderItemsGenerated,
    handleGenerateServiceOrder,
  } = useServiceorder();

  const handleEachItemServiceOrder = useCallback(async () => {
    const response = await handleGenerateServiceOrder();

    if (response) {
      onCloseModal();
      addToast({
        type: 'success',
        title: 'Ordem de serviço gerada!',
        description: 'Ordem de serviço lançada com sucesso!',
      });
    }
  }, [handleGenerateServiceOrder, onCloseModal, addToast]);

  return (
    <Container>
      <section>
        <Table>
          <caption>items da ordem de serviço</caption>
          <thead>
            <tr>
              <th>Contrato</th>
              <th>Item</th>
              <th>Descrição</th>
              <th>Data</th>
              <th>Tipo Montagem</th>
              <th>Montador(es)</th>
            </tr>
          </thead>

          <tbody>
            {serviceOrderItemsGenerated.map((serviceOrderItem) => (
              <InnerTableRow
                isGeneralOperation={false}
                key={String(serviceOrderItem.index)}
                index={serviceOrderItem.index}
                contrato={String(serviceOrderItem.NumOrc)}
                item={serviceOrderItem.CodProd}
                descricao={serviceOrderItem.Descricao}
              />
            ))}
          </tbody>
        </Table>
      </section>

      <div>
        <div>
          <IconButton
            label="Cancelar"
            color={states.ERROR}
            icon={FiX}
            iconSize={16}
            onClick={onCloseModal}
          />
        </div>
        <div>
          <IconButton
            label="Lançar O.S."
            color={main.GREEN}
            icon={BiSend}
            iconSize={16}
            onClick={handleEachItemServiceOrder}
          />
        </div>
      </div>
    </Container>
  );
};

export default InnerEachItemFrom;
