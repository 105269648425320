import styled from 'styled-components';
import { main } from 'styles/colors';

import fadeIn from 'functions/styles/fadeIn';

export const Container = styled.div`
  -webkit-animation: ${fadeIn} 1s;
  -moz-animation: ${fadeIn} 1s;
  -ms-animation: ${fadeIn} 1s;
  -o-animation: ${fadeIn} 1s;
  animation: ${fadeIn} 1s;

  > h5 {
    position: relative;
    top: 0;
    left: 0;
    text-transform: uppercase;
    border-bottom: 1px solid ${main.GRAY};
    margin-bottom: 12px;
  }

  > section {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-start;

    > div {
      width: 150px;
      margin-right: 8px;
    }
  }
`;
