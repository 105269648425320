import React, { useCallback } from 'react';
import { FiChevronRight, FiSearch } from 'react-icons/fi';

import { states } from 'styles/colors';

import { useContrato } from '../hooks/contrato';
import { useSection } from '../hooks/section';

import BaseForm from './components/BaseForm';

import { Container } from './styles';

import ChooseButton from './components/ChooseButton';

const Search: React.FC = () => {
  const { changeSection, type: section } = useSection();
  const { clearOrderData } = useContrato();

  const handleChangeCurrentForm = useCallback(
    (type) => {
      changeSection(type);
    },
    [changeSection],
  );

  return (
    <Container>
      <section>
        <ChooseButton
          isSelected={section === 'contrato'}
          onClick={() => {
            clearOrderData();
            handleChangeCurrentForm('contrato');
          }}
          color={states.SELECTED}
          label="Contrato"
          icon={section === 'contrato' ? FiSearch : FiChevronRight}
        />

        <ChooseButton
          isSelected={section === 'emlote'}
          onClick={() => {
            clearOrderData();
            handleChangeCurrentForm('emlote');
          }}
          color={states.SELECTED}
          label="Em Lote"
          icon={section === 'emlote' ? FiSearch : FiChevronRight}
        />

        <ChooseButton
          isSelected={section === 'outros'}
          onClick={() => {
            clearOrderData();
            handleChangeCurrentForm('outros');
          }}
          color={states.SELECTED}
          label="Outross"
          icon={section === 'outros' ? FiSearch : FiChevronRight}
        />
      </section>

      <BaseForm />
    </Container>
  );
};

export default Search;
