import React, { useState, useCallback } from 'react';

import { FiChevronRight, FiChevronDown } from 'react-icons/fi';

import { main } from 'styles/colors';

import { Container } from './styles';

import Item from '../Item';

import ISectionProperties from '../../interfaces/sectionProperties';

const Section: React.FC<ISectionProperties> = ({
  icon: Icon,
  title,
  itens,
  ...rest
}) => {
  const [active, setActive] = useState(true);

  const handleClick = useCallback(() => {
    if (active === false) {
      setActive(true);
    } else {
      setActive(false);
    }
  }, [active]);

  return (
    <Container active={active} {...rest}>
      <button type="button" onClick={handleClick}>
        <Icon size={15} color={main.BROWN} />
        <span>{title}</span>
        {active ? (
          <FiChevronDown size={15} color={main.GRAY} />
        ) : (
          <FiChevronRight size={15} color={main.GRAY} />
        )}
      </button>

      <section>
        {itens.map((item) => (
          <Item key={String(item.link)} label={item.label} link={item.link} />
        ))}
      </section>
    </Container>
  );
};

export default Section;
