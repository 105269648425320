import React, { useState, useCallback, useContext, createContext } from 'react';

import api from 'services/montador';

import { useAuth } from 'hooks/auth';
import { useToast } from 'hooks/toast';

import IContract from '../contrato/interfaces/contract';
import IRequestBody from './interfaces/requestBody';
import IContext from './interfaces/context';

const PeriodoContext = createContext<IContext>({} as IContext);

export const PeriodoProvider: React.FC = ({ children }) => {
  const { token } = useAuth();
  const { addToast } = useToast();

  const [data, setData] = useState<IContract[]>([]);
  const [loading, setLoading] = useState(false);

  const getContracts = useCallback(
    async ({ codloja, initialDate, finalDate }: IRequestBody) => {
      try {
        setLoading(true);

        const response = await api.post(
          '/ListarContratos',
          {
            tipobusca: 'periodo',
            codloja,
            datainicial: initialDate,
            datafinal: finalDate,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        );

        setData(response.data);
        setLoading(false);
      } catch (error) {
        const { message } = error as { message: string };
        setLoading(false);
        addToast({
          type: 'error',
          title: 'Error',
          description: error.response.data.details,
        });
      }
    },
    [token, addToast],
  );

  return (
    <PeriodoContext.Provider
      value={{ loading, contratList: data, getContracts }}
    >
      {children}
    </PeriodoContext.Provider>
  );
};

export function usePeriodo(): IContext {
  const context = useContext(PeriodoContext);

  if (!context) {
    throw new Error('usePeriodo must be used within an PeriodoContext');
  }

  return context;
}
