import React, { useRef, useState, useCallback } from 'react';

import { FiAlertTriangle } from 'react-icons/fi';

import DatePicker, {
  ReactDatePickerProps,
  registerLocale,
} from 'react-datepicker';

import pt from 'date-fns/locale/pt';

import { states } from 'styles/colors';

import IDatepickerProperties from './interfaces/datepickerProperties';

import { Field, Container, Error } from './styles';

import Header from './components/Header';

registerLocale('pt', pt);

const Datepicker: React.FC<IDatepickerProperties> = ({
  label,
  color,
  error,
  isDisabled,
  icon: Icon,
  ...rest
}) => {
  const datepickerRef = useRef<ReactDatePickerProps>(null);

  const [isFocused, setIsFocused] = useState(false);
  const [isFilled, setIsFilled] = useState(false);

  const handleInputFocus = useCallback(() => {
    setIsFocused(true);
  }, []);

  const handleInputBlur = useCallback(() => {
    setIsFocused(false);

    setIsFilled(!!datepickerRef.current?.value);
  }, []);

  return (
    <Field>
      {label && <span>{label}</span>}
      <Container
        isDisabled={isDisabled}
        isErrored={!!error}
        isFilled={isFilled}
        isFocused={isFocused}
        color={color}
      >
        {Icon && <Icon size={24} />}
        <DatePicker
          dateFormat="dd/MM/yyyy"
          locale="pt"
          onFocus={handleInputFocus}
          onBlur={handleInputBlur}
          disabled={isDisabled}
          renderCustomHeader={({
            date,
            changeYear,
            changeMonth,
            decreaseMonth,
            increaseMonth,
            prevMonthButtonDisabled,
            nextMonthButtonDisabled,
          }) => (
            <Header
              date={date}
              changeYear={changeYear}
              changeMonth={changeMonth}
              decreaseMonth={decreaseMonth}
              increaseMonth={increaseMonth}
              prevMonthButtonDisabled={prevMonthButtonDisabled}
              nextMonthButtonDisabled={nextMonthButtonDisabled}
            />
          )}
          {...rest}
        />

        {error && (
          <Error title={error}>
            <FiAlertTriangle color={states.ERROR} size={13} />
          </Error>
        )}
      </Container>
    </Field>
  );
};

export default Datepicker;
