import React from 'react';

import { Container } from './styles';

import IButtonProperties from '../../interfaces/buttonProperties';

const Button: React.FC<IButtonProperties> = ({
  color,
  icon: Icon,
  ...rest
}) => {
  return (
    <Container type="button" color={color} {...rest}>
      <div>
        <Icon size={16} />
      </div>
    </Container>
  );
};

export default Button;
