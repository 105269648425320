import React, { useState, useCallback, useContext, createContext } from 'react';

import api from 'services/montador';

import { useAuth } from 'hooks/auth';
import { useModal } from 'hooks/modal';

import IContext from './interfaces/context';
import IContract from './interfaces/contract';
import IContractItem from './interfaces/contractItem';
import IRequestBody from './interfaces/requestBody';
import IContractItemHandler from './interfaces/contractItemHandler';

const ContratoContext = createContext<IContext>({} as IContext);

export const ContratoProvider: React.FC = ({ children }) => {
  const { token } = useAuth();
  const { onShowModal } = useModal();

  const [loading, setLoading] = useState(false);

  const [contract, setContract] = useState<IContract>({} as IContract);
  const [contractItems, setContractItems] = useState<IContractItem[]>([]);
  const [marcus, setMarcus] = useState('1111');

  const getContractItems = useCallback(
    async ({ shopCode, orderCode }: IRequestBody) => {
      try {
        setLoading(true);

        const response = await api.post(
          '/ListarItensContrato',
          {
            codloja: shopCode,
            numcontrato: orderCode,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        );

        setContractItems(response.data);
      } catch (error) {
        setLoading(false);
      }
    },
    [token],
  );

  const getContract = useCallback(
    async ({ shopCode, orderCode }: IRequestBody) => {
      try {
        setLoading(true);

        const response = await api.post(
          '/ListarContratos',
          {
            tipobusca: 'contrato',
            codloja: shopCode,
            numcontrato: orderCode,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        );

        if (response.data.result === 0) {
          setLoading(false);
          throw new Error(response.data.message);
        }

        setContract(response.data[0]);
        getContractItems({
          shopCode: response.data[0].CodLoja,
          orderCode: response.data[0].NumOrc,
        });

        setLoading(false);
      } catch (error) {
        setLoading(false);
        onShowModal({
          title: 'Error',
          content: <i>{error.response.data.details}</i>,
        });
      }
    },
    [token, getContractItems, onShowModal],
  );


  const handleItem = useCallback(
    ({
      itemCode,
      mountingAmount,
      isAssemblyOutOfTown,
    }: IContractItemHandler): void => {
      const toHandleItem: IContractItem = contractItems.filter(
        (item) => item.CodProd === itemCode,
      )[0];

      if (mountingAmount) {
        toHandleItem.qtdmontagem = mountingAmount;
      }

      if (isAssemblyOutOfTown) {
        toHandleItem.motagemforadacidade = isAssemblyOutOfTown;
      }

      const newItemsArray = contractItems.filter(
        (item) => item.CodProd !== itemCode,
      );
      newItemsArray.push(toHandleItem);

      setContractItems(
        newItemsArray.sort((a, b) => {
          if (a.Item > b.Item) {
            return 1;
          }

          if (b.Item > a.Item) {
            return -1;
          }
          return 0;
        }),
      );
    },
    [contractItems],
  );

  const clearOrderData = useCallback((): void => {
    setContract({} as IContract);
    setContractItems([]);
  }, []);

  return (
    <ContratoContext.Provider
      value={{
        loading,
        contract,
        handleItem,
        getContract,
        contractItems,
        clearOrderData,
        getContractItems,
      }}
    >
      {children}
    </ContratoContext.Provider>
  );
};

export function useContrato(): IContext {
  const context = useContext(ContratoContext);

  if (!context) {
    throw new Error('useContrato must be used within an ContratoContext');
  }

  return context;
}
