import React from 'react';
import Table from 'components/utils/Table';

import { Container } from './styles';

const Itens: React.FC = () => {
  return (
    <Container>
      <h6>itens</h6>
      <Table
        caption="itens"
        sections={[
          'Item',
          'Cod. Prod',
          'Descrição',
          'Qtd. Prdido',
          'Qtd. Montada',
          'R$ Valor Unitario',
          'R$ Valor Total',
          'R$ Valor Comissão',
        ]}
      >
        <tr>
          <td>0000</td>
          <td>0000</td>
          <td>0000</td>
          <td>0000</td>
          <td>0000</td>
          <td>0000</td>
          <td>0000</td>
          <td>0000</td>
        </tr>
      </Table>
    </Container>
  );
};

export default Itens;
