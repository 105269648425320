import React from 'react';

import IButtonProperties from './interfaces/buttonProperties';

import { Container } from './styles';

const Button: React.FC<IButtonProperties> = ({ color, children, ...rest }) => (
  <Container color={color} {...rest}>
    {children}
  </Container>
);

export default Button;
