import styled from 'styled-components';

import { main } from 'styles/colors';

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;

  align-items: flex-start;
  justify-content: center;

  > section {
    width: 160px;
    height: 100%;

    border-right: 1px solid ${main.GRAY};
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 10px;

    > button {
      width: 150px;

      & + button {
        margin-top: 8px;
      }
    }
  }
`;
