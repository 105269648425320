import React from 'react';
import { FiTrello, FiPackage } from 'react-icons/fi';

import TabSection from '../components/BaseTabContainer';

import GeneralInfos from '../components/InnerOrderInfo';
import ServiceOrder from '../components/InnerServiceOrder';

const Contrato: React.FC = () => {
  return (
    <>
      <TabSection
        component={[
          {
            tabTitle: 'Geral',
            tabIcon: FiTrello,
            tabContent: GeneralInfos,
          },
          {
            tabTitle: 'Gerar O.S.',
            tabIcon: FiPackage,
            tabContent: ServiceOrder,
          },
        ]}
      />
    </>
  );
};

export default Contrato;
