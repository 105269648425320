import React from 'react';
import { Switch } from 'react-router-dom';

import Login from 'pages/Login';

import LancarOS from 'pages/Montagem/LancarOS';

import OSIndividual from 'pages/OrdemServico/Individual';
import OSPeriodo from 'pages/OrdemServico/Periodo';

import Route from './Route';

const Routes: React.FC = () => (
  <Switch>
    <Route path="/" exact component={Login} />

    {/* Remember to add isPrivate in routes below */}
    <Route path="/montagem/lancaros" component={LancarOS} isPrivate />

    <Route path="/os/individual" component={OSIndividual} isPrivate />
    <Route path="/os/periodo" component={OSPeriodo} isPrivate />
  </Switch>
);

export default Routes;
