import React from 'react';

import { useContrato } from 'pages/Montagem/LancarOS/hooks/contrato';

import Input from 'components/inputs/Input';
import Textarea from 'components/inputs/Textarea';
import Loader from 'components/utils/Loader';

import { main } from 'styles/colors';

import { Container } from './styles';

const InnerOrderInfo: React.FC = () => {
  const { contract, loading } = useContrato();

  if (loading) {
    return (
      <Container>
        <h4>Informações Gerais</h4>

        <Loader color={main.GREEN} />
      </Container>
    );
  }

  return (
    <Container>
      <h4>Informações Gerais</h4>
      {!contract.CodLoja ? (
        <main>
          <span>Faça uma Busca...</span>
        </main>
      ) : (
        <main>
          <section>
            <div>
              <Input
                isDisabled
                defaultValue={contract.CodLoja}
                name="codloja"
                label="Cod. Loja"
              />
            </div>

            <div>
              <Input
                isDisabled
                name="contrato"
                label="Contrato"
                defaultValue={contract.NumOrc}
              />
            </div>

            <div>
              <Input
                isDisabled
                name="codcliente"
                label="Cod. Cliente"
                defaultValue={contract.CodCli}
              />
            </div>

            <div>
              <Input
                isDisabled
                name="tipovenda"
                label="Tipo de Venda"
                defaultValue={contract.TipoVenda}
              />
            </div>

            <div>
              <Input
                isDisabled
                name="data"
                label="Data"
                defaultValue={contract.data}
              />
            </div>
          </section>

          <section>
            <div>
              <Input
                isDisabled
                name="nomecliente"
                label="Nome Cliente"
                defaultValue={contract.NomeCliente}
              />
            </div>
          </section>

          <section>
            <div>
              <Input
                isDisabled
                name="endereco"
                label="Endereço"
                defaultValue={contract.Endereco}
              />
            </div>

            <div>
              <Input
                isDisabled
                name="bairro"
                label="Bairro"
                defaultValue={contract.Bairro}
              />
            </div>
            <div>
              <Input
                isDisabled
                name="cidade"
                label="Cidade"
                defaultValue={contract.Cidade}
              />
            </div>
          </section>

          <section>
            <div>
              <Input
                isDisabled
                name="vendedor"
                label="Vendedor"
                defaultValue={contract.NomeFuncionario}
              />
            </div>

            <div>
              <Input
                name="situacaopedido"
                label="Situação do Pedido"
                defaultValue="Situação"
              />
            </div>
          </section>

          <section>
            <div>
              <Textarea
                isDisabled
                name="observacaoPedido"
                label="Observação Pedido"
                defaultValue={contract.Observacoes}
              />
            </div>
          </section>
        </main>
      )}
    </Container>
  );
};

export default InnerOrderInfo;
