import styled from 'styled-components';
import { shade } from 'polished';
import { main } from 'styles/colors';

export const Container = styled.button`
  background: ${main.GREEN};
  border-radius: 10px;
  border: 0;
  padding: 12px;
  color: ${main.WHITE};
  width: 100%;
  font-weight: 500;
  margin-top: 16px;
  transition: background-color 0.2s;

  &:hover {
    background: ${shade(0.2, main.GREEN)};
  }
`;
