import React from 'react';

import { usePeriodo } from 'pages/Montagem/LancarOS/hooks/periodo';

import { Container, Loader } from './styles';

import Table from './components/BaseTable';

const InnerOrders: React.FC = () => {
  const { contratList, loading } = usePeriodo();

  if (loading) {
    return (
      <Container>
        <h4>Lista de Pedidos</h4>

        <Loader />
      </Container>
    );
  }

  return (
    <Container>
      <h4>Lista de Pedidos</h4>

      {contratList.length === 0 ? <span>Faça uma Busca...</span> : <Table />}
    </Container>
  );
};

export default InnerOrders;
