import React, { useState, useCallback, useEffect } from 'react';
import { FiTrello, FiPackage, FiInfo, FiSearch, FiTrash, FiMaximize2, FiCalendar, FiChevronRight } from 'react-icons/fi';

import Dashboard from 'pages/_layouts/dashboard';

import Card from 'components/utils/Card';
import Table from 'components/utils/Table';
import DotsLoader from 'components/utils/DotsLoader';

import { Container, ContainerT, ContainerTho, TabContainer, TabButton, ContainerInputDeatils, ContainerSearch, ContainerSearchInter, Item, DivSearch } from './styles';

//import SearchBox2 from './components/SearchBox';
//import Info from './components/Info';

import Itens from './components/Itens';
import Montador from './components/Montador';
import Datepicker from 'components/inputs/Datepicker';

import { useContrato } from 'pages/Montagem/LancarOS/hooks/contrato';
import { useAuth } from 'hooks/auth';
import { useModal } from 'hooks/modal';

import api from 'services/montador';
import Input from 'components/inputs/Input';
import IconButton from 'components/buttons/IconButton';

import { states, main, toast } from 'styles/colors';
import { Checked, CheckedDiv, Line, ViewObs } from './components/Info/styles';
import Carousel from 'react-elastic-carousel'

import { Base64 } from 'js-base64';
import Select from 'components/inputs/Select';
import ChooseButton from 'pages/Montagem/LancarOS/Search/components/ChooseButton';
import { format as dateFormat, isAfter } from 'date-fns';

type Tabs = 'geral' | 'itens' | 'montador';

const Individual: React.FC = () => {

  //Start SearchBox dependencies
  const [shopCode, setShopCode] = useState<number>();
  const [shopCodeError, setShopCodeError] = useState('');

  const [startDate, setStartDate] = useState(new Date());
  const [startDateError, setStartDateError] = useState('');

  const [endDate, setEndDate] = useState(new Date());
  const [endDateError, setEndDateError] = useState('');

  const [orderCode, setOrderCode] = useState({
    value: '',
    method: ''
  });

  const [orderCodeError, setOrderCodeError] = useState('');
  const [viewDeatils, setViewDetails] = useState('')

  const [searchBox, setSearchBox] = useState([
    {
      "IDOrdemdeServico": "0000",
      "NumDocumento": "0000",
      "CodLoja": "0000",
      "CodCliente": "0000",
      "NomeCliente": "0000",
      "Data": "0000",
      "TipoDocumento": "0000",
      "statusos": "0000",
      "Obs": "0000"
    }
  ])

  const { getContract } = useContrato();
  const { token } = useAuth();
  const { onShowModal, onCloseModal } = useModal();
  const [loading, setLoading] = useState<boolean>();
  const [verifyItensOs, setVerifyItensOs] = useState<boolean>(false)
  const [typeSearch, setTypeSearch] = useState({ type: 'idos' })

  const [base64Image, setBase64Imagem] = useState([{
    "IDOrdemdeServicoItensDArquivo": "0000",
    "IDOrdemdeServicoItensDetalhes": "0000",
    "Arquivo64": "0000"
  }])

  const [listarItensOS, setListarItensOS] = useState([{
    "IDOrdemdeServicoDetalhes": "0000",
    "IDOrdemdeServicoItens": "0000",
    "IDOrdemdeServico": "0000",
    "CodProd": "0000",
    "Descricao": "0000",
    "DescricaoStatusItens": "0000",
    "IDOrdemdeServicoTipoDescricao": "0000",
    "DataHoraAgendamento": "0000",
    "DescricaoTipoOrdemdeServico": "0000",
    "IDOrdemdeServicoStatusFunc": "0000",
    "DescricaoStatusFunc": "0000",
    "DatahoraInicialOrdemdeServico": "0000",
    "DataHoraFinalOrdemdeServico": "0000",
    "Lat": "0000",
    "Lng": "0000",
    "ObsOrdemdeServico": "0000"
  }])

  const [resultlistarItensOS, setResultlistarItensOS] = useState([{
    "IDOrdemdeServicoDetalhes": "0000",
    "IDOrdemdeServicoItens": "0000",
    "IDOrdemdeServico": "0000",
    "CodProd": "0000",
    "Descricao": "0000",
    "DescricaoStatusItens": "0000",
    "IDOrdemdeServicoTipoDescricao": "0000",
    "DataHoraAgendamento": "0000",
    "DescricaoTipoOrdemdeServico": "0000",
    "IDOrdemdeServicoStatusFunc": "0000",
    "DescricaoStatusFunc": "0000",
    "DatahoraInicialOrdemdeServico": "0000",
    "DataHoraFinalOrdemdeServico": "0000",
    "Lat": "0000",
    "Lng": "0000",
    "ObsOrdemdeServico": "0000"
  }])

  const [statusMontador, setStatusMontador] = useState([
    {
      "IDOrdemdeServicoStatusFunc": "0000",
      "DescricaoStatusFunc": "0000",
      "TipoStatusFunc": "0000"
    }
  ])
  const [selectedStatusMontador, setSelectedStatusMontador] = useState('')


  const [statusItem, setStatusItem] = useState([
    {
      "IDOrdemdeServicoStatusItens": "0000",
      "DescricaoStatusItens": "0000",
      "TipoStatusItens": "0000"
    }
  ])
  const [selectedStatusItem, setSelectedStatusItem] = useState('')


  // SELECT LOJAS
  const [dataShop, setDataShop] = useState([
    {
      "CodLoja": "0000",
      "Empresa": "0000",
      "CGC": "0000",
      "Cidade": "0000",
      "UF": "0000"
    }
  ])

  const [selectedShops, setSelectedShops] = useState('')
  const [idShop, setIdShop] = useState('')
  const [idStatusItens, setIdStatusItem] = useState('')
  const [idStatusFunc, setIdStatusFunc] = useState('')
  const [iDOrdemdeServico, setIDOrdemdeServico] = useState('')
  const [iDOrdemdeServicoDetalhes, setIDOrdemdeServicoDetalhes] = useState('')
  const [iDOrdemdeServicoItens, setIDOrdemdeServicoItens] = useState('')

  useEffect(() => {
    const shopList = localStorage.getItem('@WebMontador:user')
    if (shopList !== null) setDataShop(JSON.parse(shopList).Lojas)
  }, [])


  function deleteInput() {
    setOrderCode({ value: '', method: '' })
  }

  async function handleSearch() {

      setShopCodeError('');
      setOrderCodeError('');
      setLoading(true)

      if (typeSearch.type === 'idos') {

        if (orderCode.value === '') {
          onShowModal({
            title: 'Error',
            content: <i>Informe o ID para continuar.</i>,
          });
          setLoading(false)
          return true
        }

        try {
          const response = await api.post(
            '/ListarOS',
            {
              idos: orderCode.value
            },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            },
          );

          if (response.data.result === 0) {
            throw new Error(response.data.message);
          }

          //console.log(response.data)
          localStorage.setItem('@lastResult', JSON.stringify(response.data))
          
          if(JSON.stringify(response.data) !== '[]'){
            setSearchBox(response.data)
          }else{
            onShowModal({
              title: 'Aviso',
              content: <i>Não encontrado.</i>
            })
          }
          
          setLoading(false)



        } catch (error) {
          onShowModal({
            title: 'Error',
            content: <i>{error.response.data.details}</i>,
          });
        }
      }
      
      if (typeSearch.type === 'numorc') {
        if(idShop === ''){
          onShowModal({
            title: 'Error',
            content: <i>Informe a loja para continuar.</i>,
          });
          setLoading(false)
          return true
        }

        if (orderCode.value === '') {
          onShowModal({
            title: 'Error',
            content: <i>Informe O ID para continuar.</i>,
          });
          setLoading(false)
          return true
        }

        try {
          const response = await api.post(
            '/ListarOS',
            {
              numorc: orderCode.value,
              codloja: idShop
            },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            },
          );

          if (response.data.result === 0) {
            throw new Error(response.data.message);
          }

          //console.log(response.data)
          localStorage.setItem('@lastResult', JSON.stringify(response.data))
          if(JSON.stringify(response.data) !== '[]'){
            setSearchBox(response.data)
          }else{
            onShowModal({
              title: 'Aviso',
              content: <i>Não encontrado.</i>
            })
          }
          setLoading(false)



        } catch (error) {
          onShowModal({
            title: 'Error',
            content: <i>{error.response.data.details}</i>,
          });
        }
      }

      if (typeSearch.type === 'datainicioOS') {
        try {
          const response = await api.post(
            '/ListarOS',
            {
              datainicioOS: dateFormat(startDate, 'yyy/dd/MM'),
              datafinalOS: dateFormat(endDate, 'yyy/dd/MM')
            },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            },
          );

          if (response.data.result === 0) {
            throw new Error(response.data.message);
          }

          //console.log(response.data)
          localStorage.setItem('@lastResult', JSON.stringify(response.data))
          if(JSON.stringify(response.data) !== '[]'){
            setSearchBox(response.data)
          }else{
            onShowModal({
              title: 'Aviso',
              content: <i>Não encontrado.</i>
            })
          }
          setLoading(false)



        } catch (error) {
          onShowModal({
            title: 'Error',
            content: <i>{error.response.data.details}</i>,
          });
        }
      }

  }

  function viewTextObs(text: string) {
    onShowModal({
      title: 'Observações',
      content: <i>{text}</i>,
    });
  }

  async function requesteItens(id: string) {
    setLoading(true)

    try {
      const response = await api.post(
        '/ListarItensOS',
        {
          idordemdeservico: id
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );

      if (response.data.result === 0) {
        throw new Error(response.data.message);
      }

      //console.log(response.data)
      setViewDetails(response.data[0].ObsOrdemdeServico)
      setSelectedStatusMontador(response.data[0].DescricaoStatusFunc);
      setSelectedStatusItem(response.data[0].DescricaoStatusItens)

      localStorage.setItem('@lastResultDetails', JSON.stringify(response.data[0].IDOrdemdeServicoDetalhes))
      setVerifyItensOs(true)
      setListarItensOS(response.data)
      setLoading(false)


    } catch (error) {
      setVerifyItensOs(false)
      console.log(error)
      onShowModal({
        title: 'Error',
        content: <i>{error.response.data.details}</i>,
      });
    }
  }

  function calculateString(text: string, length: number) {
    if (text.length > length) {
      return text.substring(0, length) + '...'
    } else {
      return text
    }
  }

  async function listViewImagens(index: number, e: string, IDOrdemdeServico: string) {

    const obsText = localStorage.getItem('@lastResult')
    setLoading(true)

    if (obsText !== null) {
      const converteJson = JSON.parse(obsText)
      requesteItens(converteJson[index].IDOrdemdeServico)
    }

    const inputChecked = document.getElementById(e) as HTMLInputElement | null
    let index_initial = 0
    while (index_initial < searchBox.length) {
      const inputAll = document.getElementById('check-' + index_initial) as HTMLInputElement | null

      if (e !== 'check-' + index_initial) {
        if (inputAll) inputAll.checked = false
      } else {
        if (inputAll) inputAll.checked = true
      }

      index_initial++
    }

    if (inputChecked) inputChecked.checked = true

    if (obsText !== null) {
      const converteJson = JSON.parse(obsText)
      //console.log(converteJson[index])
      setLoading(false)
    }

    const id = localStorage.getItem('@lastResultDetails')
    if (id === null) return true
  }

  async function PeriodDateItens(index: number, e: string, IDOrdemdeServico: string, data: any, ordemdeservicodetails: string) {
    const inputChecked = document.getElementById(e) as HTMLInputElement | null
    let index_initial = 0

    setResultlistarItensOS(data)
    setStartDate(new Date(data[0].DatahoraInicialOrdemdeServico))
    setEndDate(new Date(data[0].DataHoraFinalOrdemdeServico))
    setIdStatusItem(data[0].IDOrdemdeServicoStatusItens)
    setIdStatusFunc(data[0].IDOrdemdeServicoStatusFunc)
    setIDOrdemdeServico(data[0].IDOrdemdeServico)
    setIDOrdemdeServicoDetalhes(data[0].IDOrdemdeServicoDetalhes)
    setIDOrdemdeServicoItens(data[0].IDOrdemdeServicoItens)

    while (index_initial < searchBox.length) {
      const inputAll = document.getElementById('check-itens-' + index_initial) as HTMLInputElement | null

      if (e !== 'check-itens-' + index_initial) {
        if (inputAll) inputAll.checked = false
      } else {
        if (inputAll) inputAll.checked = true
      }

      index_initial++
    }

    if (inputChecked) inputChecked.checked = true

    try {
      const response = await api.post(
        '/ListarImagensItensOS',
        {
          idordemdeservicoitensdetalhes: ordemdeservicodetails
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );

      if (response.data.result === 0) {
        throw new Error(response.data.message);
      }

      //console.log(response.data)
      setBase64Imagem(response.data)


    } catch (error) {
      onShowModal({
        title: 'Error',
        content: <i>{error.response.data.details}</i>,
      });
    }

    try {
      const response = await api.get('/ListarStatusFunc', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })

      if (response.data.result === 0) {
        throw new Error(response.data.message);
      }

      //console.log(response.data)
      setStatusMontador(response.data)

    } catch (error) {
      onShowModal({
        title: 'Error',
        content: <i>{error.response.data.details}</i>,
      });
    }

    try {
      const response = await api.get('/ListarStatusItens', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })

      if (response.data.result === 0) {
        throw new Error(response.data.message);
      }

      //console.log(response.data)
      setStatusItem(response.data)

    } catch (error) {
      onShowModal({
        title: 'Error',
        content: <i>{error.response.data.details}</i>,
      });
    }


  }

  function searchOsApi(method: string) {

    if (method === 'idos') {
      setTypeSearch({ type: 'idos' })
      setOrderCode({ value: '', method: 'idos' })
    }

    if (method === 'numorc') {
      setTypeSearch({ type: 'numorc' })
      setOrderCode({ value: '', method: 'numorc' })
    }

    if (method === 'datainicioOS') {
      setTypeSearch({ type: 'datainicioOS' })
      setOrderCode({ value: '', method: 'datainicioOS' })
    }

  }
  //End SearchBox dependencies

  const [activeTab, setActiveTab] = useState<Tabs>('geral');

  let handleStatusMontador = (e: any) => {
    setSelectedStatusMontador(e.target.value);

    let index_initial = 0

    while (index_initial < statusMontador.length) {
      if (e.target.value.trim() === statusMontador[index_initial].DescricaoStatusFunc.trim()) {
        setIdStatusFunc(statusMontador[index_initial].IDOrdemdeServicoStatusFunc)
      }

      index_initial++
    }
  };

  let handleStatusItens = (e: any) => {
    setSelectedStatusItem(e.target.value)
    //console.log(statusItem)

    let index_initial = 0

    while (index_initial < statusItem.length) {
      if (e.target.value.trim() === statusItem[index_initial].DescricaoStatusItens.trim()) {
        setIdStatusItem(statusItem[index_initial].IDOrdemdeServicoStatusItens)
      }

      index_initial++
    }
  };

  let handleShopList = (e: any) => {
    setSelectedShops(e.target.value)
    //console.log(dataShop)

    let index_initial = 0

    while (index_initial < dataShop.length) {
      if (e.target.value.trim() === dataShop[index_initial].Empresa.trim()) {
        setIdShop(dataShop[index_initial].CodLoja)
      }

      index_initial++
    }
  };

  async function viewImageOrigin(imageId: string) {
    setLoading(true)
    try {
      const response = await api.post(
        '/ListarImagensItensOSOriginal',
        {
          idordemdeservicoitensdarquivo: imageId
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );

      if (response.data.result === 0) {
        throw new Error(response.data.message);
      }

      setLoading(false)

      onShowModal({
        title: 'Imagem Do Item, ID: ' + response.data[0].IDOrdemdeServicoItensDArquivo,
        content: <div><img style={{ height: 500 }} alt='imagem item' src={'data:image/png;base64,' + response.data[0].Arquivo64} /></div>,
      });


    } catch (error) {
      setLoading(false)
      onShowModal({
        title: 'Error',
        content: <i>{error.response.data.details}</i>,
      });
    }
  }

  async function changeStatusOs() {

    setLoading(true)

    try {
      const response = await api.put(
        '/ListarItensOS',
        {
          idordemdeservico: iDOrdemdeServico,
          IDOrdemdeServicoDetalhes: iDOrdemdeServicoDetalhes,
          IDOrdemdeServicoItens: iDOrdemdeServicoItens,

          IDOrdemdeServicoStatusFunc: idStatusFunc,
          IDOrdemdeServicoStatusItens: idStatusItens
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );

      if (response.data.result === 0) {
        throw new Error(response.data.message);
      }

      setLoading(false)

    } catch (error) {
      setLoading(false)
      onShowModal({
        title: 'Error',
        content: <i>{error.response.data.details}</i>,
      });
    }
  }


  const handleOpenTabContent = useCallback((tab: Tabs) => {
    setActiveTab(tab);
  }, []);

  return (
    <Dashboard>
      <Container>
        <h3>Ordem de Serviço &gt; Individual</h3>

        {/* Header search box - buscar dados */}
        <header>
          <Card title="buscar ordem de serviço">
            <DivSearch>
              <section style={{ display: 'flex', gap: 10, flexDirection: 'column', width: 200 }}>
                <ChooseButton
                  isSelected={typeSearch.type === 'idos'}
                  onClick={() => searchOsApi('idos')}
                  color={states.SELECTED}
                  label="Ordem de serviço"
                  icon={FiChevronRight}
                />

                <ChooseButton
                  isSelected={typeSearch.type === 'numorc'}
                  onClick={() => searchOsApi('numorc')}
                  color={states.SELECTED}
                  label="Orçamento"
                  icon={FiChevronRight}
                />

                <ChooseButton
                  isSelected={typeSearch.type === 'datainicioOS'}
                  onClick={() => searchOsApi('datainicioOS')}
                  color={states.SELECTED}
                  label="Periodo"
                  icon={FiSearch}
                />
              </section>

              {typeSearch.type === 'idos' ? (
                <ContainerSearch>
                  <div>
                    <Input
                      name="numos"
                      label="ID Ordem de serviço"
                      value={orderCode.value}
                      onChange={(event) => setOrderCode({ value: event.target.value, method: 'idos' })}
                      error={orderCodeError}
                    />
                  </div>

                  <div>
                    <IconButton onClick={handleSearch} color={states.SUCCEES} label="Buscar" icon={FiSearch} />
                  </div>
                  <div>
                    <IconButton onClick={() => deleteInput()} color={states.ERROR} label="Limpar" icon={FiTrash} />
                  </div>
                </ContainerSearch>
              ) : null}

              {typeSearch.type === 'numorc' ? (
                <ContainerSearch>
                  <div>
                    <Input
                      name="numos"
                      label="Nº OS"
                      // value={orderCode.value}
                      onChange={(event) => setOrderCode({ value: event.target.value, method: 'numorc' })}
                      error={orderCodeError}
                    />
                  </div>

                  <div>
                    <Select
                      label="Loja"
                      name="lojas"
                      error={shopCodeError}
                      value="status"
                      onChange={handleShopList}
                    >
                      <option value={selectedShops}>{selectedShops}</option>
                      {
                        dataShop.map((api, index) =>
                          <option key={index}>
                            {dataShop[index].Empresa}
                          </option>
                        )
                      }
                    </Select>
                  </div>

                  <div>
                    <IconButton onClick={handleSearch} color={states.SUCCEES} label="Buscar" icon={FiSearch} />
                  </div>
                  <div>
                    <IconButton onClick={() => deleteInput()} color={states.ERROR} label="Limpar" icon={FiTrash} />
                  </div>
                </ContainerSearch>
              ) : null}

              {typeSearch.type === 'datainicioOS' ? (
                <ContainerSearch>
                  <div>
                    <Datepicker
                      icon={FiCalendar}
                      label="Data Inicial"
                      color={main.GREEN}
                      selected={startDate}
                      onChange={(date: Date) => setStartDate(date)}
                      error={startDateError}
                    />
                  </div>

                  <div>
                    <Datepicker
                      icon={FiCalendar}
                      label="Data Final"
                      color={main.GREEN}
                      selected={endDate}
                      onChange={(date: Date) => setEndDate(date)}
                      error={endDateError}
                    />
                  </div>

                  <div>
                    <IconButton onClick={handleSearch} color={states.SUCCEES} label="Buscar" icon={FiSearch} />
                  </div>
                  <div>
                    <IconButton onClick={() => setStartDate(new Date())} color={states.ERROR} label="Limpar" icon={FiTrash} />
                  </div>
                </ContainerSearch>
              ) : null}

            </DivSearch>

          </Card>
        </header>

        <section>
          <Card title="dados do contrato">
            <TabContainer>
              <header>
                <TabButton
                  type="button"
                  onClick={() => handleOpenTabContent('geral')}
                  active={activeTab === 'geral'}
                >
                  <FiTrello size={16} />
                  Geral
                </TabButton>
                {/* <TabButton
                  type="button"
                  onClick={() => handleOpenTabContent('itens')}
                  active={activeTab === 'itens'}
                >
                  <FiPackage size={16} />
                  Itens
                </TabButton>
                <TabButton
                  type="button"
                  onClick={() => handleOpenTabContent('montador')}
                  active={activeTab === 'montador'}
                >
                  <FiInfo size={16} />
                  Montador
                </TabButton> */}
              </header>

              {activeTab === 'geral' && (
                <main>
                  {searchBox[0].CodLoja === '0000' ? (
                    <main>
                      <span>Faça uma Busca...</span>
                      {loading ? (<DotsLoader />) : (<></>)}
                    </main>
                  ) : (
                    <ContainerTho>

                      <Table
                        caption="Informações da OS"
                        sections={[
                          'ID Ordem',
                          'Numero Documento',
                          'Codigo Loja',
                          'Tipo Documento',
                          'Obs',
                          'Nome Cliente',
                          'Data',
                          'Status OS',
                          'Marcado'
                        ]}
                      >
                        {
                          searchBox.map((api, index) =>
                            <Line onClick={(e) => listViewImagens(index, 'check-' + index, searchBox[index].IDOrdemdeServico)} style={{ cursor: 'pointer' }} key={index}>
                              <td>{searchBox[index].IDOrdemdeServico}</td>
                              <td>{searchBox[index].NumDocumento}</td>
                              <td>{searchBox[index].CodLoja}</td>
                              <td>{searchBox[index].TipoDocumento}</td>
                              <ViewObs onClick={() => viewTextObs(searchBox[index].Obs)}>{calculateString(searchBox[index].Obs, 25)}</ViewObs>
                              <td>{searchBox[index].NomeCliente}</td>
                              <td>{dateFormat(new Date(searchBox[index].Data), 'dd/MM/yyyy')}</td>
                              <td >{searchBox[index].statusos}</td>
                              <td><CheckedDiv><Checked id={'check-' + index} onChange={(e) => listViewImagens(index, 'check-' + index, searchBox[index].IDOrdemdeServico)} type='checkbox' /></CheckedDiv></td>
                              {/* onClick={() => listViewImagens(index, 'check-' + index, searchBox[index].IDOrdemdeServico)} */}
                            </Line>
                          )
                        }
                      </Table>


                      {listarItensOS[0].CodProd === "0000" ? null : (
                        <Table
                          caption="Itens da OS"
                          sections={[
                            'ID Ordem Serviço',
                            'ID Ordem Serviço Detalhes',
                            'ID Ordem Serviço Item',
                            'Codigo Produto',
                            'Descrição',
                            'Data Agendamento',
                            'Data',
                            'Tipo Ordem De Serviço',
                            'Marcado',
                          ]}
                        >
                          {
                            listarItensOS.map((api, index) =>
                              <Line style={{ cursor: 'pointer' }} onClick={(e) => PeriodDateItens(index, 'check-itens-' + index, searchBox[index].IDOrdemdeServico, listarItensOS, listarItensOS[index].IDOrdemdeServicoDetalhes)} key={index}>
                                <td>{listarItensOS[index].IDOrdemdeServico}</td>
                                <td>{listarItensOS[index].IDOrdemdeServicoDetalhes}</td>
                                <td>{listarItensOS[index].IDOrdemdeServicoItens}</td>
                                <td>{listarItensOS[index].CodProd}</td>
                                <ViewObs onClick={() => viewTextObs(listarItensOS[index].Descricao)}>{calculateString(listarItensOS[index].Descricao, 25)}</ViewObs>
                                <td>{listarItensOS[index].IDOrdemdeServicoTipoDescricao}</td>
                                <td>{dateFormat(new Date(listarItensOS[index].DataHoraAgendamento), 'dd/MM/yyyy')}</td>
                                <td onClick={(e) => PeriodDateItens(index, 'check-' + index, searchBox[index].IDOrdemdeServico, listarItensOS, listarItensOS[index].IDOrdemdeServicoDetalhes)}>{listarItensOS[index].DescricaoTipoOrdemdeServico}</td>
                                <td><CheckedDiv><Checked id={'check-itens-' + index} onChange={(e) => PeriodDateItens(index, 'check-itens-' + index, searchBox[index].IDOrdemdeServico, listarItensOS, listarItensOS[index].IDOrdemdeServicoDetalhes)} type='checkbox' /></CheckedDiv></td>
                              </Line>
                            )
                          }
                        </Table>
                      )}

                      {loading ? (<DotsLoader />) : (<></>)}

                      {resultlistarItensOS[0].IDOrdemdeServicoDetalhes !== "0000" ? (
                        <div>
                          <h6>Detalhes</h6>
                          <main>
                            <section>
                              <Card title="">
                                <ContainerSearch>
                                  <ContainerSearchInter>
                                    <ContainerInputDeatils>
                                      <div style={{ width: '150px !important' }}>
                                        <Select
                                          label="Status Montador"
                                          name="lojas"
                                          error={shopCodeError}
                                          value="status"
                                          onChange={handleStatusMontador}
                                        >
                                          <option value={selectedStatusMontador}>{selectedStatusMontador}</option>
                                          {
                                            statusMontador.map((api, index) =>
                                              <option key={index}>
                                                {statusMontador[index].DescricaoStatusFunc}
                                              </option>
                                            )
                                          }
                                        </Select>
                                      </div>

                                      <div style={{ width: '150px !important' }}>
                                        <Select
                                          label="Status Item"
                                          name="lojas"
                                          error={shopCodeError}
                                          value="status"
                                          onChange={handleStatusItens}
                                        >
                                          <option value={selectedStatusItem}>{selectedStatusItem}</option>
                                          {
                                            statusItem.map((api, index) =>
                                              <option key={index}>
                                                {statusItem[index].DescricaoStatusItens}
                                              </option>
                                            )
                                          }
                                        </Select>
                                      </div>

                                      <div style={{ width: '150px !important' }}>
                                        <Datepicker
                                          icon={FiCalendar}
                                          label="Data Hora Inicial"
                                          color={main.GREEN}
                                          selected={startDate}
                                          onChange={(date: Date) => setStartDate(date)}
                                          error={startDateError}
                                          dateFormat="dd/MM/yyyy - h:mm"
                                          disabled
                                        />
                                      </div>

                                      <div style={{ width: '140px !important' }}>
                                        <Datepicker
                                          icon={FiCalendar}
                                          label="Data Hora Final"
                                          color={main.GREEN}
                                          selected={endDate}
                                          onChange={(date: Date) => setEndDate(date)}
                                          error={endDateError}
                                          dateFormat="dd/MM/yyyy - h:mm"
                                          disabled
                                        />
                                      </div>

                                      <div></div>

                                      <div>
                                        <IconButton onClick={changeStatusOs} color={states.SUCCEES} label="Salvar" icon={FiSearch} />
                                      </div>
                                    </ContainerInputDeatils>
                                  </ContainerSearchInter>


                                </ContainerSearch>
                              </Card>
                            </section>

                            <section style={{ minWidth: 80 }}>
                              <Card title="Observação da O.S.">
                                {calculateString(viewDeatils, 80)}
                                <IconButton style={{ width: 130 }} onClick={() => viewTextObs(viewDeatils)} color={states.SUCCEES} label="Ver mais" icon={FiMaximize2} />
                              </Card>
                            </section>

                            <section>
                              <Card title="Imagens">
                                <Carousel isRTL={false}>
                                  {
                                    base64Image.map((api, index) =>
                                      <Item onClick={() => viewImageOrigin(base64Image[index].IDOrdemdeServicoItensDArquivo)} key={index}><img style={{ width: '100%', height: '100%' }} src={'data:image/png;base64,' + base64Image[index].Arquivo64} /></Item>
                                    )
                                  }
                                </Carousel>
                              </Card>
                            </section>
                          </main>
                        </div>
                      ) : null}

                    </ContainerTho>
                  )}
                </main>
              )}

              {activeTab === 'itens' && (
                <main>
                  <Itens />
                </main>
              )}

              {activeTab === 'montador' && (
                <main>
                  <Montador />
                </main>
              )}
            </TabContainer>
          </Card>
        </section>
      </Container>
    </Dashboard>
  );
};

export default Individual;
