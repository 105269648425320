import styled from 'styled-components';

import { main } from 'styles/colors';

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;

  > h4 {
    position: relative;
    top: 0;
    left: 0;
    text-transform: uppercase;
    margin-bottom: 8px;
    border-bottom: 1px solid ${main.GRAY};
  }

  > main {
    display: flex;
    flex: 1;
    flex-direction: column;

    > span {
      align-self: center;
      color: ${main.DARKGRAY};
      text-transform: uppercase;
      font-style: italic;
    }

    > section {
      display: flex;
      flex: 1;
      flex-direction: row;
      margin-top: 12px;

      > div {
        display: flex;
        flex: 1;
        width: 100%;
        padding-left: 12px;
        padding-right: 12px;

        textarea {
          max-height: 80px;
        }
      }
    }
  }
`;
