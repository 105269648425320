import React from 'react';

import { useSection } from 'pages/Montagem/LancarOS/hooks/section';

import FormContrato from '../InnerFormContrato';
import FormEmlote from '../InnerFormEmlote';
import FormOutros from '../InnerFormOutros';

import { Container } from './styles';

const BaseForm: React.FC = () => {
  const { type } = useSection();

  let Form: React.ComponentType;

  switch (type) {
    case 'contrato':
      Form = FormContrato;
      break;

    case 'emlote':
      Form = FormEmlote;
      break;

    case 'outros':
      Form = FormOutros;
      break;

    default:
      Form = FormContrato;
      break;
  }

  return (
    <Container>
      <Form />
    </Container>
  );
};

export default BaseForm;
