import React from 'react';

import Dashboard from 'pages/_layouts/dashboard';

import Card from 'components/utils/Card';

import Provider from './hooks';

import { Container } from './styles';

import Search from './Search';
import Content from './Content';

const LancarOS: React.FC = () => {
  return (
    <Dashboard>
      <Provider>
        <Container>
          <h3>Lançar Ordem de Serviço</h3>

          <header>
            <Card title="buscar">
              <Search />
            </Card>
          </header>

          <main>
            <Card>
              <Content />
            </Card>
          </main>
        </Container>
      </Provider>
    </Dashboard>
  );
};

export default LancarOS;
