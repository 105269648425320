import styled from 'styled-components';
import rotationEffect from 'functions/styles/rotationEffect';

import { main } from 'styles/colors';

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;

  > h4 {
    position: relative;
    top: 0;
    left: 0;
    text-transform: uppercase;
    margin-bottom: 8px;
    border-bottom: 1px solid ${main.GRAY};
  }

  > span {
    align-self: center;
    color: ${main.DARKGRAY};
    text-transform: uppercase;
    font-style: italic;
  }
`;

export const Loader = styled.div`
  border: 4px solid ${main.LIGHTGRAY};
  border-top: 4px solid ${main.GREEN};
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: ${rotationEffect} 2s linear infinite;
  margin: 0 auto;
`;
