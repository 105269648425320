import React from 'react';
import { FiX } from 'react-icons/fi';

import { main } from 'styles/colors';

import IModalProperties from 'components/utils/Modal/interfaces/modalProperties';

import { useModal } from '../../hooks/modal';

import { Container } from './styles';

const Modal: React.FC<IModalProperties> = ({ modal, isVisible }) => {
  const { onCloseModal } = useModal();

  return (
    <Container isVisible={isVisible}>
      <div>
        <header>
          <h4>{modal.title}</h4>

          <FiX size={24} color={main.WHITE} onClick={onCloseModal} />
        </header>
        <main>{modal.content}</main>
      </div>
    </Container>
  );
};

export default Modal;
