import styled, { css } from 'styled-components';
import { main } from 'styles/colors';

import ISectionContainerProperties from '../../interfaces/sectionContainerProperties';

export const Container = styled.div<ISectionContainerProperties>`
  width: 100%;

  > button {
    border: none;
    outline: none;

    width: 100%;
    padding: 4px;

    color: ${main.BROWNBAGE};

    transition: 0.2s;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;

    cursor: pointer;

    > span {
      display: flex;
      flex: 1;
      font-size: 14px;
      text-transform: capitalize;
      font-weight: bold;
    }

    > svg {
      margin-right: 7px;
      margin-left: 7px;
    }

    ${(props) =>
      props.active
        ? css`
            background-color: ${main.WHITE};
            border-left: 2px solid ${main.GREEN};
          `
        : css`
            background-color: ${main.WHITE};
          `}

    &:hover {
      background-color: ${main.LIGHTGRAY};
    }
  }

  > section {
    padding: 0 18px;
    display: none;
    overflow: hidden;
    transition: 0.4s;

    ${(props) =>
      props.active
        ? css`
            display: block;
          `
        : css`
            display: none;
          `}
  }
`;
