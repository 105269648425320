import styled from 'styled-components';

import { main } from 'styles/colors';

import rotationEffect from 'functions/styles/rotationEffect';

import IStyledContainerProperties from './interfaces/styledContainerProperties';

export const Container = styled.div<IStyledContainerProperties>`
  border: 4px solid ${main.WHITE};
  border-top: 4px solid ${(props) => props.color};
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: ${rotationEffect} 2s linear infinite;
  margin: 0 auto;
`;
