import styled from 'styled-components';
import { lighten } from 'polished';
import { main } from 'styles/colors';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  > div {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    flex-direction: row;

    > div {
      margin-left: 16px;
      margin-top: 16px;
      width: 160px;
    }
  }

  > section {
    height: 400px;
    overflow-y: scroll;
    overflow-x: hidden;

    scrollbar-color: ${main.GREEN} ${main.WHITE};
    scrollbar-width: thin;

    &::-webkit-scrollbar {
      width: 6px;
      position: relative;
    }

    &::-webkit-scrollbar-track {
      background-color: ${main.WHITE};
    }

    &::-webkit-scrollbar-thumb {
      background-color: ${main.GREEN};
    }

    &::-webkit-scrollbar-thumb:hover {
      background-color: ${lighten(0.05, main.GREEN)};
    }
  }
`;

export const Table = styled.table`
  width: 100%;
  margin: 0 auto;
  border-collapse: collapse;
  border: 1px solid ${main.GRAY};
  font-size: 14px;
  text-align: left;

  > caption {
    text-transform: uppercase;
    font-weight: bold;
  }

  th,
  td {
    padding: 5px;
    border: 1px solid ${main.GRAY};
  }
`;
