import styled from 'styled-components';

import { main } from 'styles/colors';

export const Container = styled.div`
  min-width: 950px;

  > h3 {
    position: relative;
    top: 0;
    left: 0;

    border-bottom: 1px solid ${main.GRAY};
  }

  > header {
    margin-top: 16px;
  }

  > main {
    margin-top: 30px;
    width: 100%;
  }
`;
