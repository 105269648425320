import React from 'react';
import { GrTools } from 'react-icons/gr';
import { FiFileMinus } from 'react-icons/fi';
import { FaUser } from 'react-icons/fa';

import { useAuth } from 'hooks/auth';

import { Container, SectionTitle, User } from './styles';

import Section from './components/Section';
import montagem from './utils/itemsMontagem';
import manutencao from './utils/itemsManutencao';

const Sidemenu: React.FC = () => {
  const { user } = useAuth();
  return (
    <Container>
      <User>
        <section>
          <FaUser size={24} />
        </section>
        <div>
          <strong>{user.funcionario[0].Login}</strong>
          <span>{`Codígo: ${user.funcionario[0].CodUsuario}`}</span>
        </div>
      </User>

      <main>
        <SectionTitle>ordem de serviço</SectionTitle>
        <Section icon={GrTools} title="montagem" itens={montagem} />

        <Section
          icon={FiFileMinus}
          title="ordem de serviço"
          itens={manutencao}
        />
      </main>
    </Container>
  );
};

export default Sidemenu;
