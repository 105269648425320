import React from 'react';

import { SectionProvider } from './section';
import { TabMenuProvider } from './tabmenu';
import { ContratoProvider } from './contrato';
import { PeriodoProvider } from './periodo';
import { MontagemProvider } from './montagem';
import { ModalProvider } from './modal';
import { ServiceOrderProvider } from './serviceorder';

const Provider: React.FC = ({ children }) => (
  <SectionProvider>
    <TabMenuProvider>
      <MontagemProvider>
        <ContratoProvider>
          <PeriodoProvider>
            <ServiceOrderProvider>
              <ModalProvider>{children}</ModalProvider>
            </ServiceOrderProvider>
          </PeriodoProvider>
        </ContratoProvider>
      </MontagemProvider>
    </TabMenuProvider>
  </SectionProvider>
);

export default Provider;
