import styled, { css } from 'styled-components';

import { main } from 'styles/colors';

interface ITabButtonProps {
  active?: boolean;
}

export const ContainerInputDeatils = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;

  div {
    width: 150px;
  }
`

export const DivSearch = styled.div`
  display: flex;
  gap: 20px;
`

export const ContainerSearchInter = styled.div`
  
`

export const ContainerBottomDetails = styled.div`
  display: flex;
  justify-content: end;
`

export const ContainerSearch = styled.div`
  max-width: 500px;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
  margin-top: 8px;

  > section {
    display: flex;
    flex: 1;
    flex-direction: column;

    align-items: flex-start;

    padding-left: 8px;
    padding-right: 8px;
  }

  > div {
    display: flex;
    flex: 1;

    align-items: center;
    justify-content: center;

    padding-left: 8px;
    padding-right: 8px;
  }
`;

export const Item = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 250px;
  width: 100%;
  background-color: #683bb7;
  color: #fff;
  margin: 15px;
  font-size: 4em;
`;

export const ContainerTho = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;

  h6 {
    text-transform: uppercase;
    display: flex;
    flex: 1;
    width: 100%;

    border-bottom: 1px solid ${main.GRAY};
  }

  > div {
    margin-top: 30px;

    > main {
      display: flex;
      flex: 1;
      flex-direction: row;
      align-items: center;
      justify-content: space-around;

      > section {
        width: 48%;
        padding: 8px;
      }
    }
  }
`;

export const ContainerT = styled.div`
  max-width: 500px;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
  margin-top: 8px;

  > section {
    display: flex;
    flex: 1;
    flex-direction: column;

    align-items: flex-start;

    padding-left: 8px;
    padding-right: 8px;
  }

  > div {
    display: flex;
    flex: 1;

    align-items: center;
    justify-content: center;

    padding-left: 8px;
    padding-right: 8px;
  }
`;

export const ContainerCard = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;

  h6 {
    text-transform: uppercase;
    display: flex;
    flex: 1;
    width: 100%;

    border-bottom: 1px solid ${main.GRAY};
  }

  > div {
    margin-top: 30px;

    > main {
      display: flex;
      flex: 1;
      flex-direction: row;
      align-items: center;
      justify-content: space-around;

      > section {
        width: 48%;
        padding: 8px;
      }
    }
  }
`;

export const Container = styled.div`
  > h3 {
    position: relative;
    top: 0;
    left: 0;

    border-bottom: 1px solid ${main.GRAY};
  }

  > header {
    margin-top: 16px;
  }

  > section {
    margin-top: 30px;
    width: 100%;
  }
`;

export const TabContainer = styled.div`
  width: 100%;
  display: flex;
  flex: 1;
  flex-direction: column;

  > header {
    overflow: hidden;
    border-bottom: none;
  }

  > main {
    padding: 16px;
    border: 1px solid ${main.GRAY};
  }
`;

export const TabButton = styled.button<ITabButtonProps>`
  cursor: pointer;
  outline: none;
  border: none;
  background-color: ${main.WHITE};

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: center;

  float: left;

  padding: 12px;
  text-align: center;
  transition: 0.3s;

  &:hover {
    color: ${main.GREEN};
  }

  ${(props) =>
    props.active &&
    css`
      border: 1px solid ${main.GRAY};
      border-top: 3px solid ${main.GREEN};
      border-bottom: none;
      color: ${main.GREEN};
    `}

  svg {
    margin-right: 5px;
  }
`;
