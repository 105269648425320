import React, { createContext, useCallback, useState, useContext } from 'react';
import platform from 'platform';

import ipify from 'services/ipify';
import getToken from 'services/token';
import montador from 'services/montador';

import IAuth from './interfaces/auth';
import IContext from './interfaces/context';
import IRequestBody from './interfaces/requestBody';
import ISignInError from './interfaces/signInError';

const AuthContext = createContext<IContext>({} as IContext);

export const AuthProvider: React.FC = ({ children }) => {
  const [loading, setLoading] = useState(false);

  const [data, setData] = useState<IAuth>(() => {
    const token = localStorage.getItem('@WebMontador:token');
    const user = localStorage.getItem('@WebMontador:user');

    if (token && user) {
      return { token, user: JSON.parse(user) };
    }

    return {} as IAuth;
  });

  const signIn = useCallback(async (requestBody: IRequestBody): Promise<
    true | ISignInError
  > => {
    setLoading(true);

    let externalIp = '';

    try {
      const response = await ipify.get('/?format=json', {
        timeout: 5000,
      });

      externalIp = response.data.ip;
    } catch (error) {
      const { message } = error as { message: string };

      if (message === 'timeout of 5000ms exceeded') {
        externalIp = '';
      }
    }

    const requestData: IRequestBody = {
      externalIp,
      browser: platform.name,
      browserVersion: platform.version,
      operatingSystem: platform.os?.family,
      operatingSystemVersion: platform.os?.version,
      operatingSystemArchitecture: platform.os?.architecture,
      type: requestBody.type,
      password: requestBody.password,
      resendcode: requestBody.resendcode,
      authorizationCode: requestBody.authorizationCode,
      AuthenticationCode: requestBody.AuthenticationCode,
      user: requestBody.type === 'cnpj' ? requestBody.user : '',
      cnpj: requestBody.type === 'cnpj' ? requestBody.cnpj : '',
      email: requestBody.type === 'email' ? requestBody.email : '',
    };

    try {
      const response = await getToken.post('/auth', requestData);

      const { token } = response.data;

      if (token) {
        const getUser = await montador.get('/UsuarioInfo', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        const user = getUser.data;

        localStorage.setItem('@WebMontador:token', token);
        localStorage.setItem('@WebMontador:user', JSON.stringify(user));
        setLoading(false);
        setData({ token, user });
      }

      return true;
    } catch (error) {
      setLoading(false);
      return {
        code: error.response.data.code,
        message: error.response.data.message,
      };
    }
  }, []);

  const signOut = useCallback(async () => {
    setLoading(true);

    localStorage.removeItem('@WebMontador:token');
    localStorage.removeItem('@WebMontador:user');

    setLoading(false);
    setData({} as IAuth);
  }, []);

  return (
    <AuthContext.Provider
      value={{ user: data.user, token: data.token, loading, signIn, signOut }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export function useAuth(): IContext {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  return context;
}
