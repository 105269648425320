import React, { useState } from 'react';
import { FiSearch, FiCalendar } from 'react-icons/fi';

import { useAuth } from 'hooks/auth';
import IShop from 'hooks/auth/interfaces/shop';

import IconButton from 'components/buttons/IconButton';
import Select from 'components/inputs/Select';
import Input from 'components/inputs/Input';
import Datepicker from 'components/inputs/Datepicker';

import { main, states } from 'styles/colors';

const Carga: React.FC = () => {
  const { user } = useAuth();

  const [generalOperation, setGeneralOperation] = useState(true);

  const [shopCode, setShopCode] = useState<number>();
  const [shopCodeError, setShopCodeError] = useState('');

  const [startDate, setStartDate] = useState(new Date());
  const [startDateError, setStartDateError] = useState('');

  const [endDate, setEndDate] = useState(new Date());
  const [endDateError, setEndDateError] = useState('');

  return (
    <>
      <h5>Carga</h5>

      <section>
        <label htmlFor="campo-radio2">
          <input
            type="radio"
            value="0"
            checked={generalOperation}
            onChange={(e) => {
              e.target.value === '0' && setGeneralOperation(true);
            }}
            name="campo-radio"
            id="campo-radio2"
          />
          <span>Numera da Carga: </span>
        </label>

        <span>&nbsp;</span>
        <span>&nbsp;</span>
        <span>&nbsp;</span>
        <span>&nbsp;</span>
        <span>&nbsp;</span>
        <span>&nbsp;</span>

        <label htmlFor="campo-radio1">
          <input
            type="radio"
            value="1"
            checked={!generalOperation}
            onChange={(e) => {
              e.target.value === '1' && setGeneralOperation(false);
            }}
            name="campo-radio"
            id="campo-radio1"
          />
          <span>Data da Carga: </span>
        </label>
      </section>

      <section>
        <div
          style={{
            display: generalOperation === true ? 'block' : 'none',
          }}
        >
          <Input name="numcarga" label="Numero da Carga" />
        </div>

        <div
          style={{
            display: generalOperation === false ? 'block' : 'none',
            width: 500,
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Select
              label="Lojas"
              name="lojas"
              error={shopCodeError}
              onChange={(event) => {
                if (event.target.value === ' ') {
                  return;
                }

                setShopCode(
                  user.Lojas.filter(
                    (loja: IShop) =>
                      loja.CodLoja === Number(event.target.value),
                  )[0].CodLoja,
                );
              }}
            >
              <option value=" "> </option>

              {user.Lojas.map((loja: IShop) => (
                <option key={String(loja.CodLoja)} value={loja.CodLoja}>
                  {`${loja.CodLoja} - ${loja.Empresa}`}
                </option>
              ))}
            </Select>

            <Datepicker
              icon={FiCalendar}
              label="Data Inicial"
              color={main.GREEN}
              selected={startDate}
              onChange={(date: Date) => setStartDate(date)}
              error={startDateError}
            />

            <Datepicker
              icon={FiCalendar}
              label="Data Final"
              color={main.GREEN}
              selected={endDate}
              onChange={(date: Date) => setEndDate(date)}
              error={endDateError}
            />
          </div>
        </div>

        <div>
          <IconButton color={states.SUCCEES} label="Buscar" icon={FiSearch} />
        </div>
      </section>
    </>
  );
};

export default Carga;
