import React from 'react';
import { FiChevronsRight } from 'react-icons/fi';

import { Link, useLocation } from 'react-router-dom';

import { main } from 'styles/colors';

import { Container } from './styles';

import IItemProperties from '../../interfaces/itemProperties';

const Item: React.FC<IItemProperties> = ({ label, link }) => {
  const { pathname } = useLocation();

  return (
    <Container color={link === pathname ? main.GREEN : main.BROWN}>
      <FiChevronsRight
        size={11}
        color={link === pathname ? main.GREEN : main.GRAY}
      />

      <Link to={link}>
        <p>{label}</p>
      </Link>
    </Container>
  );
};

export default Item;
