import React from 'react';

import { Container } from './styles';

import IComponentProperties from './interfaces/componentProperties';

const ChooseButton: React.FC<IComponentProperties> = ({
  label,
  iconSize,
  icon: Icon,
  isSelected,
  ...rest
}) => {
  return (
    <Container isSelected={isSelected} {...rest}>
      {label && <span>{label}</span>}

      <div>
        <Icon size={iconSize || 16} />
      </div>
    </Container>
  );
};

export default ChooseButton;
