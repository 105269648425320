import React from 'react';

import Table from 'components/utils/Table';
import Card from 'components/utils/Card';

import { Container } from './styles';



const Info: React.FC = () => {
  return (
    <Container>
      <h6>Informações da OS</h6>
      <Table
        caption="Informações da OS"
        sections={[
          'Loja O.S.',
          'Contrato',
          'Seq.',
          'Data',
          'Tipo Montagem',
          'Status Montagem',
          'Cod Solicitante',
          'Nome Solicitante',
          'CPF / CGC',
        ]}
      >
        <tr>
          <td>0000</td>
          <td>0000</td>
          <td>0000</td>
          <td>0000</td>
          <td>0000</td>
          <td>0000</td>
          <td>0000</td>
          <td>0000</td>
          <td>0000</td>
        </tr>
      </Table>

      <div>
        <h6>Detalhes</h6>
        <main>
          <section>
            <Card title="Observação da O.S.">a</Card>
          </section>
          <section>
            <Card title="Observação do Pedido">b</Card>
          </section>
          <section>
            <Card title="Imagens da O.S.">c</Card>
          </section>
        </main>
      </div>
    </Container>
  );
};

export default Info;
