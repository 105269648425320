import { css } from 'styled-components';
import { toast } from 'styles/colors';

export default {
  info: css`
    background: ${toast.BG_INFO};
    color: ${toast.CL_INFO};
  `,
  success: css`
    background: ${toast.BG_SUCCESS};
    color: ${toast.CL_SUCCESS};
  `,
  error: css`
    background: ${toast.BG_ERROR};
    color: ${toast.CL_ERROR};
  `,
};
