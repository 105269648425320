import React from 'react';

import { useSection } from '../hooks/section';

import Contrato from './Contrato';
import Outros from './Outros';
import Periodo from './Periodo';

const Content: React.FC = () => {
  const { type } = useSection();

  switch (type) {
    case 'contrato':
      return <Contrato />;

    case 'outros':
      return <Outros />;

    case 'emlote':
      return <Periodo />;

    default:
      return <Contrato />;
  }
};

export default Content;
