import React from 'react';
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi';
import getYear from 'date-fns/getYear';
import getMonth from 'date-fns/getMonth';

import IDatepickerHeaderProperties from '../../interfaces/datepickerHeaderProperties';

import range from '../../functions/range';
import monthsList from '../../utils/monthsList';

import { Container } from './styles';

const Header: React.FC<IDatepickerHeaderProperties> = ({
  date,
  changeYear,
  changeMonth,
  decreaseMonth,
  increaseMonth,
  prevMonthButtonDisabled,
  nextMonthButtonDisabled,
}) => {
  const years = range(1990, getYear(new Date()) + 1);

  return (
    <Container>
      <button
        type="button"
        onClick={decreaseMonth}
        disabled={prevMonthButtonDisabled}
      >
        <FiChevronLeft size={20} color="#fff" />
      </button>

      <select
        value={monthsList[getMonth(date)]}
        onChange={({ target: { value } }) => {
          changeMonth(monthsList.indexOf(value));
        }}
      >
        {monthsList.map((option) => (
          <option key={option} value={option}>
            {option}
          </option>
        ))}
      </select>

      <select
        value={getYear(date)}
        onChange={({ target: { value } }) => changeYear(Number(value))}
      >
        {years.map((option) => (
          <option key={option} value={option}>
            {option}
          </option>
        ))}
      </select>

      <button
        type="button"
        onClick={increaseMonth}
        disabled={nextMonthButtonDisabled}
      >
        <FiChevronRight size={20} color="#fff" />
      </button>
    </Container>
  );
};

export default Header;
