import styled from 'styled-components';

import { main } from 'styles/colors';

export const Container = styled.div`
  width: 1300px;
  display: flex;
  flex: 1;
  flex-direction: column;

  > section {
    width: 100%;
    display: flex;
    flex: 1;
  }
`;

export const Header = styled.header`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-bottom: 12px;
  width: 350px;

  > section {
    width: 100%;
    display: flex;
    flex: 1;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;

    > span {
      background-color: ${main.DARKGRAY};
      width: 2px;
      margin-left: 12px;
      margin-right: 12px;
    }

    > label {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;

      > input {
        margin-right: 5px;
      }

      > span {
        margin-top: 5px;
      }
    }
  }
`;
