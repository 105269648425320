import styled from 'styled-components';

import { main } from 'styles/colors';

type PropTypeBg = {
  htmlFor: string;
};

export const Checked = styled.input`
    cursor: pointer;
`

export const ViewObs = styled.td`
  cursor: pointer;
`

export const CheckedDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const Line = styled.tr`

`

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;

  h6 {
    text-transform: uppercase;
    display: flex;
    flex: 1;
    width: 100%;

    border-bottom: 1px solid ${main.GRAY};
  }

  > div {
    margin-top: 30px;

    > main {
      display: flex;
      flex: 1;
      flex-direction: row;
      align-items: center;
      justify-content: space-around;

      > section {
        width: 48%;
        padding: 8px;
      }
    }
  }
`;
