import React, { useState, useCallback } from 'react';
import { format as formatDate } from 'date-fns';
import { BiSend } from 'react-icons/bi';
import {
  FiChevronRight,
  FiPlusCircle,
  FiMinusCircle,
  FiX,
} from 'react-icons/fi';

import { useToast } from 'hooks/toast';
import { useModal } from 'pages/Montagem/LancarOS/hooks/modal';
import { useMontagem } from 'pages/Montagem/LancarOS/hooks/montagem';
import { useServiceorder } from 'pages/Montagem/LancarOS/hooks/serviceorder';

import IAssembler from 'pages/Montagem/LancarOS/hooks/montagem/interfaces/assembler';
import IMountingType from 'pages/Montagem/LancarOS/hooks/montagem/interfaces/mountingType';

import Select from 'components/inputs/Select';
import Datepicker from 'components/inputs/Datepicker';
import IconButton from 'components/buttons/IconButton';

import { main, states } from 'styles/colors';

import InnerTableRow from '../InnerTableRow';

import {
  Container,
  DateSelectContainer,
  AssemblerSelectContainer,
  Table,
  ButtonContainer,
} from './styles';

import { TDateFormType } from '../_shared/interfaces/dateFormType';

const InnerGeneralForm: React.FC = () => {
  const { addToast } = useToast();
  const { onCloseModal } = useModal();
  const { assemblers, mountingTypes } = useMontagem();
  const {
    serviceOrderItemsGenerated,
    handleGenerateServiceOrder,
  } = useServiceorder();

  const [currentDateForm, setCurrentDateForm] = useState<TDateFormType>(
    'scheduling',
  );

  const [selectedAssembler, setSelectedAssembler] = useState<IAssembler>();
  const [selectedAssemblerError, setSelectedAssemblerError] = useState('');

  const [mountingType, setMountingType] = useState<IMountingType>();
  const [serviceOrderDate, setServiceOrderDate] = useState(new Date());
  const [selectedsAssemblers, setSelectedsAssemblers] = useState<IAssembler[]>(
    [],
  );

  const handleSelectedAssembler = useCallback(() => {
    if (selectedAssembler) {
      const tempSelectedsAssemblers = selectedsAssemblers;

      const assembler = selectedsAssemblers.filter(
        (assemble) => assemble.CodFunc === selectedAssembler.CodFunc,
      )[0];

      if (assembler) {
        setSelectedAssemblerError('Montador já selecionado!');
        return;
      }

      tempSelectedsAssemblers.push(selectedAssembler);

      setSelectedsAssemblers(tempSelectedsAssemblers);
      setSelectedAssemblerError('');
      setSelectedAssembler(undefined);
    } else {
      setSelectedAssemblerError('Selecione um montador!');
    }
  }, [selectedsAssemblers, selectedAssembler]);

  const handleRemoveOneAssemblerFromList = useCallback(
    (codFunc: number) => {
      const newAssemblersList = selectedsAssemblers.filter(
        (assembler) => assembler.CodFunc !== codFunc,
      );

      setSelectedsAssemblers(newAssemblersList);
    },
    [selectedsAssemblers],
  );

  const handleServiceOrder = useCallback(async () => {
    const assemblersCode: number[] = [];

    selectedsAssemblers.forEach((assembler) => {
      assemblersCode.push(assembler.CodFunc);
    });

    if (assemblersCode.length > 0) {
      serviceOrderItemsGenerated.forEach((item) => {
        item.montadores = assemblersCode;
        item.idTipoMontagem = mountingType?.IDMotagemTipo;
        item.dataLimite =
          currentDateForm === 'limit'
            ? formatDate(serviceOrderDate, 'yyyy-MM-dd 00:00:00')
            : '';
        item.dataAgendamento =
          currentDateForm === 'scheduling'
            ? formatDate(serviceOrderDate, 'yyyy-MM-dd HH:mm:ss')
            : '';
      });
    }

    const response = await handleGenerateServiceOrder();

    if (response) {
      onCloseModal();
      addToast({
        type: 'success',
        title: 'Ordem de serviço gerada!',
        description: 'Ordem de serviço lançada com sucesso!',
      });
      //atualizar
    }
  }, [
    addToast,
    onCloseModal,
    mountingType,
    currentDateForm,
    serviceOrderDate,
    selectedsAssemblers,
    handleGenerateServiceOrder,
    serviceOrderItemsGenerated,
  ]);

  return (
    <Container>
      <section>
        <Select
          label="Tipos de Montagem"
          name="tipomontagem"
          onChange={(event) => {
            setMountingType(
              mountingTypes.filter(
                (type) => type.IDMotagemTipo === Number(event.target.value),
              )[0],
            );
          }}
        >
          <option value=" "> </option>

          {mountingTypes.map((option) => (
            <option
              key={String(option.IDMotagemTipo)}
              value={option.IDMotagemTipo}
            >
              {option.Descricaotipomontagem}
            </option>
          ))}
        </Select>

        <DateSelectContainer>
          <span>Escolha a Data: </span>
          <div>
            <IconButton
              onClick={() => {
                setCurrentDateForm('scheduling');
              }}
              color={main.GREEN}
              label="Agendamento"
              icon={FiChevronRight}
            />

            <IconButton
              onClick={() => setCurrentDateForm('limit')}
              color={main.GREEN}
              label="Data limite"
              icon={FiChevronRight}
            />

            {currentDateForm === 'scheduling' && (
              <Datepicker
                label="Agendamento"
                color={main.GREEN}
                selected={serviceOrderDate}
                showTimeSelect
                dateFormat="dd/MM/yyyy, HH:mm"
                onChange={(date: Date) => setServiceOrderDate(date)}
              />
            )}

            {currentDateForm === 'limit' && (
              <>
                <Datepicker
                  label="Data Limite"
                  color={main.GREEN}
                  selected={serviceOrderDate}
                  onChange={(date: Date) => setServiceOrderDate(date)}
                />
              </>
            )}
          </div>
        </DateSelectContainer>

        <AssemblerSelectContainer>
          <Select
            label="Montadores"
            name="montadores"
            error={selectedAssemblerError}
            onChange={(event) => {
              setSelectedAssembler(
                assemblers.filter(
                  (assembler) =>
                    assembler.CodFunc === Number(event.target.value),
                )[0],
              );
            }}
          >
            <option value=" "> </option>

            {assemblers
              .sort((first, second) => {
                if (first.NomeFuncionario > second.NomeFuncionario) {
                  return 1;
                }

                if (second.NomeFuncionario > first.NomeFuncionario) {
                  return -1;
                }
                return 0;
              })
              .map((option) => (
                <option key={String(option.CodFunc)} value={option.CodFunc}>
                  {`${option.CodFunc} - ${option.NomeFuncionario}`}
                </option>
              ))}
          </Select>

          <IconButton
            icon={FiPlusCircle}
            color={states.SUCCEES}
            onClick={handleSelectedAssembler}
          />
        </AssemblerSelectContainer>

        <div>
          <Table>
            <thead>
              <tr>
                <th>Cod. Montador</th>
                <th colSpan={2}>Nome</th>
              </tr>
            </thead>
            <tbody>
              {selectedsAssemblers.length === 0 ? (
                <tr>
                  <td colSpan={2}>nenhum montador selecionado</td>
                </tr>
              ) : (
                selectedsAssemblers.map((montador) => (
                  <tr key={String(montador.CodFunc)}>
                    <td>{montador.CodFunc}</td>

                    <td>{montador.NomeCompleto}</td>

                    <td>
                      <IconButton
                        color={states.ERROR}
                        icon={FiMinusCircle}
                        onClick={() => {
                          handleRemoveOneAssemblerFromList(montador.CodFunc);
                        }}
                      />
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </Table>
        </div>

        <ButtonContainer>
          <div>
            <IconButton
              label="Cancelar"
              color={states.ERROR}
              icon={FiX}
              iconSize={16}
              onClick={onCloseModal}
            />
          </div>

          <div>
            <IconButton
              label="Lançar O.S."
              color={main.GREEN}
              icon={BiSend}
              iconSize={16}
              onClick={handleServiceOrder}
            />
          </div>
        </ButtonContainer>
      </section>

      <section>
        <Table>
          <caption>items da ordem de serviço</caption>
          <thead>
            <tr>
              <th>Contrato</th>
              <th>Item</th>
              <th>Descrição</th>
            </tr>
          </thead>
          <tbody>
            {serviceOrderItemsGenerated.map((serviceOrderItem) => (
              <InnerTableRow
                isGeneralOperation
                key={String(serviceOrderItem.index)}
                index={serviceOrderItem.index}
                contrato={String(serviceOrderItem.NumOrc)}
                item={serviceOrderItem.CodProd}
                descricao={serviceOrderItem.Descricao}
              />
            ))}
          </tbody>
        </Table>
      </section>
    </Container>
  );
};

export default InnerGeneralForm;
