import { keyframes } from 'styled-components';

export default keyframes`
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
`;
