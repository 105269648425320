import styled from 'styled-components';

import { main } from 'styles/colors';

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;

  > h4 {
    position: relative;
    top: 0;
    left: 0;
    text-transform: uppercase;
    margin-bottom: 8px;
    border-bottom: 1px solid ${main.GRAY};
  }

  button {
    margin-top: 16px;
  }

  > main {
    display: flex;
    flex: 1;
    flex-direction: column;

    > span {
      align-self: center;
      color: ${main.DARKGRAY};
      text-transform: uppercase;
      font-style: italic;
    }

    > section {
      display: flex;
      flex: 1;
      flex-direction: column;
      margin-bottom: 24px;

      > h6 {
        position: relative;
        top: 0;
        left: 0;
        text-transform: uppercase;
        font-weight: bold;
        border-bottom: 1px solid ${main.GRAY};
      }

      > main {
        display: flex;
        flex: 1;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;

        > h6 {
          position: relative;
          top: 0;
          left: 0;
          text-transform: uppercase;
          margin-bottom: 8px;
          border-bottom: 1px solid ${main.GRAY};
        }

        > div {
          > div {
            height: 100%;
          }
          box-sizing: border-box;
          width: 400px;
          height: 260px;
          padding: 8px;
        }
      }
    }
  }
`;
