import React, { useState } from 'react';
import { FiSearch } from 'react-icons/fi';

import { states } from 'styles/colors';

import { useAuth } from 'hooks/auth';
import IShop from 'hooks/auth/interfaces/shop';

import IconButton from 'components/buttons/IconButton';
import Select from 'components/inputs/Select';
import Input from 'components/inputs/Input';

import { Container } from './styles';

const Exposicao: React.FC = () => {
  const { user } = useAuth();

  const [shopCode, setShopCode] = useState<number>();
  const [shopCodeError, setShopCodeError] = useState('');

  const [orderCode, setOrderCode] = useState('');
  const [orderCodeError, setOrderCodeError] = useState('');

  return (
    <Container>
      <h5>Exposição</h5>

      <section>
        <div>
          <Select
            label="Lojas"
            name="lojas"
            error={shopCodeError}
            onChange={(event) => {
              if (event.target.value === ' ') {
                return;
              }

              setShopCode(
                user.Lojas.filter(
                  (loja: IShop) => loja.CodLoja === Number(event.target.value),
                )[0].CodLoja,
              );
            }}
          >
            <option value=" "> </option>

            {user.Lojas.map((loja: IShop) => (
              <option key={String(loja.CodLoja)} value={loja.CodLoja}>
                {`${loja.CodLoja} - ${loja.Empresa}`}
              </option>
            ))}
          </Select>
        </div>

        <div>
          <Input
            name="numpedido"
            label="N° do Contrato"
            onChange={(event) => setOrderCode(event.target.value)}
            error={orderCodeError}
          />
        </div>

        <div>
          <IconButton color={states.SUCCEES} label="Buscar" icon={FiSearch} />
        </div>
        <div> </div>
      </section>
    </Container>
  );
};

export default Exposicao;
