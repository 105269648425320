import React, { useCallback } from 'react';
import { FiFilePlus } from 'react-icons/fi';

import { useToast } from 'hooks/toast';
import { useModal } from 'pages/Montagem/LancarOS/hooks/modal';
import { useContrato } from 'pages/Montagem/LancarOS/hooks/contrato';
import { useServiceorder } from 'pages/Montagem/LancarOS/hooks/serviceorder';

import IContractItem from 'pages/Montagem/LancarOS/hooks/contrato/interfaces/contractItem';
import IServiceOrderItemGenerated from 'pages/Montagem/LancarOS/hooks/serviceorder/interfaces/serviceOrderItemGenerated';

import { Title, Container, Table, Button } from './styles';

import ItemRow from '../InnerItemRow';
import Modal from '../../../ModalGenerateServiceOrder';

const InnerItemsTable: React.FC = () => {
  const { addToast } = useToast();
  const { onShowModal } = useModal();
  const { contract, contractItems } = useContrato();
  const { handleServiceOrderItemsGenerateds } = useServiceorder();

  const modal = useCallback(() => {
    onShowModal({
      title: 'Lançar O.S.',
      content: <Modal />,
    });
  }, [onShowModal]);

  const handleItensToServiceOrder = useCallback(() => {
    const draft: IContractItem[] = [];
    const serviceOrderItems: IServiceOrderItemGenerated[] = [];

    contractItems.forEach((item) => {
      if (!item.qtdmontagem || item.qtdmontagem > item.qtdpedido) {
        addToast({
          type: 'error',
          title: 'Quantidade Invalida',
          description: `Insira uma quantidade de montagem valida para o produto ${item.Descricao}`,
        });
        return;
      }

      for (let index = 0; index < item.qtdmontagem; index++) {
        draft.push(item);
      }
    });

    if (draft.length > 0) {
      draft.forEach((item, index) => {
        serviceOrderItems.push({
          index,
          CodLoja: contract.CodLoja,
          CodProd: item.CodProd,
          Descricao: item.Descricao,
          Item: item.Item,
          NumOrc: item.NumOrc,
          motagemforadacidade: item.motagemforadacidade,
        });
      });
    }

    if (serviceOrderItems.length > 0) {
      handleServiceOrderItemsGenerateds(serviceOrderItems);
      modal();
    }
  }, [
    contract,
    contractItems,
    handleServiceOrderItemsGenerateds,
    addToast,
    modal,
  ]);

  return (
    <>
      <Title>Itens</Title>
      <Container>
        <Table>
          <thead>
            <tr>
              <th>Contrato</th>
              <th>Item</th>
              <th>Cod. Prod.</th>
              <th>Descrição</th>
              <th>Itens do Pedido</th>
              <th>Itens Montados</th>
              <th>Saldo da Montagem</th>
              <th>Quantidade a Montar</th>
              <th>R$ Comissão</th>
              <th>R$ Valor Total</th>
              <th>Montagem fora da cidade</th>
            </tr>
          </thead>
          <tbody>
            {contractItems.length > 0 &&
              contractItems.map((item) => (
                <ItemRow key={String(item.CodProd)} item={item} />
              ))}
          </tbody>
        </Table>
      </Container>

      <Button
        onClick={async () => {
          handleItensToServiceOrder();
        }}
      >
        <FiFilePlus />
        Lançar O.S.
      </Button>
    </>
  );
};

export default InnerItemsTable;
