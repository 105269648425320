import React, { createContext, useCallback, useContext, useState } from 'react';

import IContext from './interfaces/context';
import { TSectionType } from './interfaces/sectionType';

const SectionContext = createContext<IContext>({} as IContext);

export const SectionProvider: React.FC = ({ children }) => {
  const [data, setData] = useState<TSectionType>('contrato');

  const changeSection = useCallback((type: TSectionType) => {
    setData(type);
  }, []);

  return (
    <SectionContext.Provider value={{ type: data, changeSection }}>
      {children}
    </SectionContext.Provider>
  );
};

export function useSection(): IContext {
  const context = useContext(SectionContext);

  if (!context) {
    throw new Error('useSection must be used within a SectionProvider');
  }

  return context;
}
