import React, { useState, useRef, useCallback } from 'react';

import { FiAlertCircle } from 'react-icons/fi';

import { Field, Container, Error } from './styles';

import IInputProperties from './interfaces/inputProperties';

const Input: React.FC<IInputProperties> = ({
  label,
  error,
  isDisabled,
  icon: Icon,
  ...rest
}) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const [isFocused, setIsFocused] = useState(false);
  const [isFilled, setIsFilled] = useState(false);

  const handleInputFocus = useCallback(() => {
    setIsFocused(true);
  }, []);

  const handleInputBlur = useCallback(() => {
    setIsFocused(false);

    setIsFilled(!!inputRef.current?.value);
  }, []);

  return (
    <Field>
      {label && <span>{label}</span>}
      <Container
        isDisabled={isDisabled}
        isErrored={!!error}
        isFilled={isFilled}
        isFocused={isFocused}
      >
        {Icon && <Icon size={20} />}
        <input
          disabled={isDisabled}
          onFocus={handleInputFocus}
          onBlur={handleInputBlur}
          ref={inputRef}
          {...rest}
        />

        {error && (
          <Error title={error}>
            <FiAlertCircle color="#c53030" size={13} />
          </Error>
        )}
      </Container>
    </Field>
  );
};

export default Input;
