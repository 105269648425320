import styled, { css } from 'styled-components';
import { lighten } from 'polished';

import bounce from 'functions/styles/bounce';

import { main } from 'styles/colors';

const ball = css`
  width: 10px;
  height: 10px;
  border-radius: 50%;

  animation: 2s ${bounce} ease infinite;
`;

export const Container = styled.div`
  width: 30%;
  height: 28px;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
`;

export const First = styled.div`
  ${ball};
  background-color: ${lighten(0.1, main.GREEN)};
`;

export const Second = styled.div`
  ${ball};
  background-color: ${lighten(0.13, main.GREEN)};
  animation-delay: 0.25s;
`;

export const Third = styled.div`
  ${ball};
  background-color: ${lighten(0.16, main.GREEN)};
  animation-delay: 0.5s;
`;

export const Fourth = styled.div`
  ${ball};
  background-color: ${lighten(0.19, main.GREEN)};
  animation-delay: 0.75s;
`;
