import React from 'react';
import { FiList, FiTrello, FiPackage } from 'react-icons/fi';

import TabSection from '../components/BaseTabContainer';

import Orders from '../components/InnerOrders';
import OrderInfo from '../components/InnerOrderInfo';
import ServiceOrder from '../components/InnerServiceOrder';

const Periodo: React.FC = () => {
  return (
    <>
      <TabSection
        component={[
          {
            tabTitle: 'Pedidos',
            tabIcon: FiList,
            tabContent: Orders,
          },
          {
            tabTitle: 'Geral',
            tabIcon: FiTrello,
            tabContent: OrderInfo,
          },
          {
            tabTitle: 'Gerar O.S.',
            tabIcon: FiPackage,
            tabContent: ServiceOrder,
          },
        ]}
      />
    </>
  );
};

export default Periodo;
