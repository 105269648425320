import React from 'react';

// import { FiSearch, FiBell, FiMail } from 'react-icons/fi';
import { GiExitDoor } from 'react-icons/gi';

import { states } from 'styles/colors';

import { useAuth } from 'hooks/auth';

import icon from 'assets/images/logos/app/icon.svg';
import name from 'assets/images/logos/app/name.svg';

import Button from './components/Button';

import { Container } from './styles';

const Header: React.FC = () => {
  const { signOut } = useAuth();
  return (
    <Container>
      <section>
        <img src={icon} alt="Montador" />

        <span>
          <img src={name} alt="montador" />
        </span>
      </section>

      <div>
        {/* <section>
          <Button icon={FiSearch} />
        </section>
        <section>
          <Button icon={FiBell} />
        </section>
        <section>
          <Button icon={FiMail} />
        </section> */}
        <section>
          <Button icon={GiExitDoor} color={states.ERROR} onClick={signOut} />
        </section>
      </div>
    </Container>
  );
};

export default Header;
