import React from 'react';

import Table from 'components/utils/Table';

import { Container } from './styles';

const Montador: React.FC = () => {
  return (
    <Container>
      <h6>itens</h6>
      <Table caption="itens" sections={['Cod Montador', 'Montador']}>
        <tr>
          <td>0000</td>
          <td>nome teste teste nome</td>
        </tr>
      </Table>
    </Container>
  );
};

export default Montador;
