import styled from 'styled-components';
import { lighten } from 'polished';
import { main } from 'styles/colors';

export const Table = styled.table`
  width: 1050px;
  margin: 0 auto;
  border-collapse: collapse;
  border: 1px solid ${main.GRAY};

  font-size: 12px;

  > caption {
    text-align: left;
    text-transform: uppercase;
    font-weight: bold;
  }
  th {
    font-size: 14px;
  }

  th,
  td {
    text-align: left;
    padding: 3px;
    border-bottom: 1px solid ${main.GRAY};
  }

  td {
    > button {
      width: 30px;
      height: 30px;
      margin: 0 auto;
    }
  }

  button {
    margin-left: 4px;
    margin-right: 4px;
    width: 30px;
    height: 30px;
  }

  > tfoot {
    > tr {
      > td {
        > div {
          padding: 0;
          margin: 0;
          margin: 0 auto;
          display: flex;
          flex: 1;
          flex-direction: row;
          align-items: center;

          max-width: 900px;
          height: 40px;

          overflow-x: scroll;
          overflow-y: hidden;

          scrollbar-color: ${main.GREEN} ${main.WHITE};
          scrollbar-width: thin;

          &::-webkit-scrollbar {
            height: 6px;
            position: relative;
          }
          &::-webkit-scrollbar-track {
            background-color: ${main.WHITE};
          }
          &::-webkit-scrollbar-thumb {
            background-color: ${main.GREEN};
          }
          &::-webkit-scrollbar-thumb:hover {
            background-color: ${lighten(0.05, main.GREEN)};
          }
        }
      }
    }
  }
`;
