import styled from 'styled-components';
import { lighten, shade } from 'polished';

import { main } from 'styles/colors';

export const Title = styled.h6`
  position: relative;
  top: 0;
  left: 0;
  text-transform: uppercase;
  margin-bottom: 8px;
  border-bottom: 1px solid ${main.GRAY};
`;

export const Container = styled.div`
  padding-bottom: 5px;
  border-bottom: 1px solid ${main.GRAY};
  max-height: 320px;

  overflow-x: hidden;
  overflow-y: scroll;

  scrollbar-color: ${main.GREEN} ${main.WHITE};
  scrollbar-width: thin;

  &::-webkit-scrollbar {
    width: 6px;
    position: relative;
  }
  &::-webkit-scrollbar-track {
    background-color: ${main.WHITE};
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${main.GREEN};
  }
  &::-webkit-scrollbar-thumb:hover {
    background-color: ${lighten(0.05, main.GREEN)};
  }
`;

export const Table = styled.table`
  width: 1050px;
  margin: 0 auto;
  border-collapse: collapse;
  border: 1px solid ${main.GRAY};

  font-size: 12px;

  > caption {
    text-align: left;
    text-transform: uppercase;
    font-weight: bold;
  }

  th {
    font-size: 14px;
  }

  th,
  td {
    margin: 5px;
    text-align: left;
    border: 1px solid ${main.GRAY};
    padding-left: 5px;
    padding-right: 5px;
  }
`;

export const Button = styled.button`
  display: flex;
  align-self: flex-end;
  align-items: center;
  justify-content: center;

  width: 150px;
  padding: 8px;
  border: 0;
  border-radius: 4px;

  background-color: ${main.GREEN};
  color: ${main.WHITE};

  transition: 0.4s;

  &:hover {
    background-color: ${shade(0.2, main.GREEN)};
  }

  svg {
    margin-right: 5px;
  }
`;
