import React, { createContext, useState, useCallback, useContext } from 'react';

import browsercode from 'services/autenticacao';

import { useToast } from 'hooks/toast';

import IContext from './interfaces/context';
import IBrowserCode from './interfaces/browsercode';

const BrowserCodeContext = createContext<IContext>({} as IContext);

export const BrowserCodeProvider: React.FC = ({ children }) => {
  const { addToast } = useToast();
  const [data, setData] = useState<IBrowserCode>(() => {
    const code = localStorage.getItem('@WebMontador:BrowserCode');

    if (code) {
      return { code };
    }

    return {} as IBrowserCode;
  });

  const requestCode = useCallback(async () => {
    try {
      const response = await browsercode.get('/GerarCodigoAutorizacao');
      const { authorizationcode } = response.data;

      localStorage.setItem('@WebMontador:BrowserCode', authorizationcode);
      setData({ code: authorizationcode });
    } catch (error) {
      const { message } = error as { message: string };
      addToast({
        type: 'info',
        title: 'Aviso!',
        description: message,
      });
    }
  }, [addToast]);

  return (
    <BrowserCodeContext.Provider
      value={{ browserCode: data.code, requestCode }}
    >
      {children}
    </BrowserCodeContext.Provider>
  );
};

export function useBrowserCode(): IContext {
  const context = useContext(BrowserCodeContext);

  if (!context) {
    throw new Error(
      'useBrowserCode must be used within an BrowserCodeProvider',
    );
  }

  return context;
}
