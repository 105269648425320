const main = {
  WHITE: '#fff',
  WHITE01: '#f2f2f2',
  WHITE02: '#FFFFFc',
  WHITE03: '#FFFFEE',

  GRAY: '#ccc',
  DARKGRAY: '#9c9c9c',
  LIGHTGRAY: '#F0F0F0',

  BROWN: '#333',
  BROWNBAGE: '#666360',

  BLACK: '#181820',
  FADEBLACK: '#0003',

  GREEN: '#007D8B',
};

const toast = {
  BG_INFO: '#ebf8ff',
  CL_INFO: '#3172b7',

  BG_SUCCESS: '#e6fffa',
  CL_SUCCESS: '#2e656a',

  BG_ERROR: '#fddede',
  CL_ERROR: '#c53030',
};

const states = {
  SELECTED: '#01baef',
  SUCCEES: '#20bf55',
  ERROR: '#c20114',
};

export { main, toast, states };
