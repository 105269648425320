import React, { useState } from 'react';

import { useMontagem } from 'pages/Montagem/LancarOS/hooks/montagem';
import { useServiceorder } from 'pages/Montagem/LancarOS/hooks/serviceorder';

import Loader from 'components/utils/Loader';

import { main } from 'styles/colors';

import InnerGeneralForm from './components/InnerGeneralForm';
import InnerEachItemFrom from './components/InnerEachItemFrom';

import { Container, Header } from './styles';

const Modal: React.FC = () => {
  const { loading: moutingLoading } = useMontagem();
  const { loading: serviceOrderLoading } = useServiceorder();

  const [generalOperation, setGeneralOperation] = useState(true);

  if (moutingLoading || serviceOrderLoading) {
    return <Loader color={main.GREEN} />;
  }

  return (
    <Container>
      <Header>
        <h4>Lançar Ordem de Serviço para:</h4>
        <section>
          <label htmlFor="campo-radio2">
            <input
              type="radio"
              value="0"
              checked={generalOperation}
              onChange={(e) => {
                e.target.value === '0' && setGeneralOperation(true); 
              }}
              name="campo-radio"
              id="campo-radio2"
            />
            <span>Todos os Itens</span>
          </label>

          <span>&nbsp;</span>

          <label htmlFor="campo-radio1">
            <input
              type="radio"
              value="1"
              checked={!generalOperation}
              onChange={(e) => {
                e.target.value === '1' && setGeneralOperation(false);
              }}
              name="campo-radio"
              id="campo-radio1"
            />
            <span>Cada item individualmente</span>
          </label>
        </section>
      </Header>

      <section>
        {generalOperation ? <InnerGeneralForm /> : <InnerEachItemFrom />}
      </section>
    </Container>
  );
};

export default Modal;
