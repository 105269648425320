import axios, { AxiosResponse } from 'axios';
import servicesConfig from 'config/services';

const api = axios.create({
  baseURL: `https://apiservices.nwsys.com.br/nwsys_montador/Montador`,
});

const responseInterceptor = (response: AxiosResponse) => response;

const errorInterceptor = (error: any) => {
  if (error.response.status === 406) {
    //console.log(error.response)
  }
  return Promise.reject(error);
};

api.interceptors.response.use(responseInterceptor, errorInterceptor);


export default api;
