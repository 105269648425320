import React from 'react';

import { FiSearch, FiTrash } from 'react-icons/fi';

import Input from 'components/inputs/Input';
import IconButton from 'components/buttons/IconButton';

import { states } from 'styles/colors';

import { Container } from './styles';

const SearchBox: React.FC = () => {
  return (
    <Container>
      <section>
        <label htmlFor="campo-radio1">
          <input type="radio" value="0" name="campo-radio" id="campo-radio1" />
          Todas
        </label>

        <label htmlFor="campo-radio2">
          <input type="radio" value="0" name="campo-radio" id="campo-radio2" />
          Montando
        </label>

        <label htmlFor="campo-radio3">
          <input type="radio" value="0" name="campo-radio" id="campo-radio3" />
          Concluidas
        </label>

        <label htmlFor="campo-radio4">
          <input type="radio" value="0" name="campo-radio" id="campo-radio4" />
          Canceldas
        </label>
      </section>

      <section>
        <Input name="datainicial" label="Data Inicial" error="" />
        <Input name="datafinal" label="Data Final" error="" />
        <Input name="montador" label="Montador" error="" />
      </section>

      <section>
        <IconButton color={states.SUCCEES} label="Buscar" icon={FiSearch} />

        <IconButton color={states.ERROR} label="Limpar" icon={FiTrash} />
      </section>
    </Container>
  );
};

export default SearchBox;
