import styled from 'styled-components';
import { lighten } from 'polished';

import { main } from 'styles/colors';

export const Wrapper = styled.div`
  background-color: ${main.LIGHTGRAY};
  height: 100%;
`;

export const Content = styled.div`
  background-color: ${main.LIGHTGRAY};
  width: calc(100vw - 200px);
  height: calc(100vh - 8.5vh);
  padding: 16px;

  position: absolute;
  bottom: 0;
  right: 0;

  overflow-x: none;
  overflow-y: scroll;

  scrollbar-color: ${main.GREEN} ${main.LIGHTGRAY};
  scrollbar-width: thin;

  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    position: relative;
  }

  &::-webkit-scrollbar-track {
    background-color: ${main.LIGHTGRAY};
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${main.GREEN};
  }
  &::-webkit-scrollbar-thumb:hover {
    background-color: ${lighten(0.05, main.GREEN)};
  }
`;
