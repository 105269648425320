import React from 'react';

import { Container, First, Second, Third, Fourth } from './styles';

const DotsLoader: React.FC = () => {
  return (
    <Container>
      <First />
      <Second />
      <Third />
      <Fourth />
    </Container>
  );
};

export default DotsLoader;
