import styled, { css } from 'styled-components';

import { main } from 'styles/colors';

import IStyledContainerProperties from './interfaces/styledContainerProperties';

export const Container = styled.div<IStyledContainerProperties>`
  z-index: 9999;
  position: absolute;
  top: 0;

  width: 100%;
  height: 100%;

  background-color: ${main.FADEBLACK};

  display: flex;
  align-items: center;
  justify-content: center;

  > div {
    color: ${main.BROWNBAGE};

    max-height: 95%;
    max-width: 98%;

    background-color: ${main.WHITE};
    border-radius: 8px;

    > header {
      border-top-right-radius: 8px;
      border-top-left-radius: 8px;
      width: 100%;
      height: 45px;

      background-color: ${main.GREEN};

      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      padding: 12px;

      color: ${main.WHITE};
    }

    > main {
      display: flex;
      flex: 1;
      padding: 16px;
    }
  }

  ${(props) =>
    props.isVisible
      ? css`
          display: flex;
        `
      : css`
          display: none;
        `}
`;
