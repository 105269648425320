import React from 'react';
import { main } from 'styles/colors';

import IIconButtonProperties from './interfaces/iconButtonProperties';

import { Container } from './styles';

const IconButton: React.FC<IIconButtonProperties> = ({
  color,
  label,
  iconSize,
  icon: Icon,
  ...rest
}) => {
  return (
    <Container color={color} {...rest}>
      {label && <span>{label}</span>}

      <div>
        <Icon size={iconSize || 16} color={main.WHITE} />
      </div>
    </Container>
  );
};

export default IconButton;
