import React from 'react';

import { Container } from './styles';

import { TAuthButtonProperties } from './interfaces/authButtonProperties';

const AuthButton: React.FC<TAuthButtonProperties> = ({ children, ...rest }) => (
  <Container {...rest}>{children}</Container>
);

export default AuthButton;
