import React, { useState, useEffect } from 'react';

import logo from 'assets/images/logos/app/default-horizontal.svg';
import nwsys from 'assets/images/logos/nwsys/default.svg';

import { useBrowserCode } from 'hooks/browsercode';
import { useToast } from 'hooks/toast';

import {
  Container,
  Content,
  AnimationContainer,
  AuthType,
  AuthOption,
  Background,
} from './styles';

import FormEmail from './form/FormEmail';
import FormCnpj from './form/FormCnpj';

const SignIn: React.FC = () => {
  const { addToast } = useToast();
  const { browserCode, requestCode } = useBrowserCode();
  const [authMethod, setAuthMethod] = useState(0);

  useEffect(() => {
    async function getAuthorizationCode(): Promise<void> {
      try {
        if (browserCode) {
          return;
        }
        requestCode();
      } catch (error) {
        const { message } = error as { message: string };
        addToast({
          type: 'error',
          title: 'Erro!',
          description: message,
        });
      }
    }

    getAuthorizationCode();
  }, [browserCode, requestCode, addToast]);

  return (
    <Container>
      <Background />

      <Content>
        <AnimationContainer>
          <header>
            <img src={logo} alt="montador - by nwsys soluções em tecnologias" />

            <h3>LOGIN</h3>
          </header>

          <AuthType>
            <strong>entrar com:</strong>
            <AuthOption
              isSelected={authMethod === 0}
              onClick={() => {
                setAuthMethod(0);
              }}
            >
              email
            </AuthOption>
            <AuthOption
              isSelected={authMethod === 1}
              onClick={() => {
                setAuthMethod(1);
              }}
            >
              cnpj | usuário
            </AuthOption>
          </AuthType>

          <section>
            {authMethod === 0 && <FormEmail />}
            {authMethod === 1 && <FormCnpj />}
          </section>
        </AnimationContainer>
      </Content>
      <img src={nwsys} alt="nwsys - soluções em tecnologias" />
    </Container>
  );
};

export default SignIn;
