import React, {
  useState,
  createContext,
  useContext,
  useCallback,
  useEffect,
} from 'react';

import montador from 'services/montador';
import { useHistory } from "react-router-dom";

import { useAuth } from 'hooks/auth';
import { useToast } from 'hooks/toast';
import { useContrato } from '../contrato';

import IContext from './interfaces/context';
import IRequestBody from './interfaces/requestBody';
import IServiceOrder from './interfaces/serviceOrder';
import IServiceOrderItem from './interfaces/serviceOrderItem';
import IServiceOrderItemImage from './interfaces/serviceOrderItemImage';
import IServiceOrderItemDetail from './interfaces/serviceOrderItemDetail';
import IServiceOrderItemGenerated from './interfaces/serviceOrderItemGenerated';
import IServiceOrderItemGeneratedHandler from './interfaces/serviceOrderItemGeneratedHandler';

const ServiceOrderContext = createContext<IContext>({} as IContext);

export const ServiceOrderProvider: React.FC = ({ children }) => {
  const { token } = useAuth();
  const { addToast } = useToast();

  const { contract } = useContrato();
  const history = useHistory();

  const [loading, setLoading] = useState(false);

  const [serviceOrders, setServiceOrders] = useState<IServiceOrder[]>([]);
  const [serviceOrderItems, setServiceOrderItems] = useState<
    IServiceOrderItem[]
  >([]);
  const [serviceOrderItemImages, setServiceOrderItemImages] = useState<
    IServiceOrderItemImage[]
  >([]);
  const [serviceOrderItemDetails, setServiceOrderItemDetails] = useState<
    IServiceOrderItemDetail[]
  >([]);
  const [serviceOrderItemsGenerated, setServiceOrderItemsGenerated] = useState<
    IServiceOrderItemGenerated[]
  >([]);

  const getServiceOrderList = useCallback(
    async (req: IRequestBody): Promise<void> => {
      try {
        setLoading(true);
        const response = await montador.post(
          '/ListarOS',
          {
            codloja: req.codloja,
            numcontrato: req.numcontrato,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        );

        setServiceOrders(response.data);
        setLoading(false);
      } catch (error) {
        const { message } = error as { message: string };

        setLoading(false);
        addToast({
          type: 'error',
          title: 'Error',
          description: message,
        });
      }
    },
    [token, addToast],
  );

  const getServiceOrderItems = useCallback(
    async (idmontadoros: number): Promise<void> => {
      try {
        setLoading(true);

        const response = await montador.post(
          '/ListarItensOS',
          {
            idmontadoros,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        );

        setServiceOrderItems(response.data);
        setLoading(false);
      } catch (error) {
        const { message } = error as { message: string };
        setLoading(false);
        addToast({
          type: 'error',
          title: 'Error',
          description: message,
        });
      }
    },
    [token, addToast],
  );

  const getServiceOrderItemDetails = useCallback(
    async (idmontadorositens: number): Promise<void> => {
      try {
        setLoading(true);

        const response = await montador.post(
          '/ListarDetalhesItensOS',
          {
            idmontadorositens,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        );

        setServiceOrderItemDetails(response.data);
        setLoading(false);
      } catch (error) {
        const { message } = error as { message: string };

        setLoading(false);
        addToast({
          type: 'error',
          title: 'Error',
          description: message,
        });
      }
    },
    [token, addToast],
  );

  const getServiceOrderItemImages = useCallback(
    async (idmontadorositensdetalhes: number): Promise<void> => {
      try {
        setLoading(true);

        const response = await montador.post(
          '/ListarImagensItensOS',
          {
            idmontadorositensdetalhes,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        );

        setServiceOrderItemImages(response.data);
        setLoading(false);
      } catch (error) {
        const { message } = error as { message: string };

        setLoading(false);
        addToast({
          type: 'error',
          title: 'Error',
          description: message,
        });
      }
    },
    [token, addToast],
  );

  useEffect(() => {
    function getOs(): void {
      if (!contract.CodLoja) {
        return;
      }

      setServiceOrderItems([]);
      setServiceOrderItemImages([]);
      setServiceOrderItemDetails([]);
      setServiceOrderItemsGenerated([]);

      getServiceOrderList({
        codloja: contract.CodLoja,
        numcontrato: contract.NumOrc,
      });
    }

    getOs();
  }, [getServiceOrderList, contract]);

  const handleServiceOrderItemsGenerateds = useCallback(
    (items: IServiceOrderItemGenerated[]): void => {
      setServiceOrderItemsGenerated(items);
    },
    [],
  );

  const clearServiceOrderItemsGenerateds = useCallback(() => {
    setServiceOrderItems([]);
  }, []);

  const handleOneServiceOrderItemGenerated = useCallback(
    ({
      index,
      idTipoMontagem,
      montadores,
      dataAgendamento,
      dataLimite,
    }: IServiceOrderItemGeneratedHandler): void => {
      const toHandleItem: IServiceOrderItemGenerated = serviceOrderItemsGenerated.filter(
        (item) => item.index === index,
      )[0];

      if (idTipoMontagem) {
        toHandleItem.idTipoMontagem = idTipoMontagem;
      }

      if (montadores) {
        toHandleItem.montadores = montadores;
      }

      if (dataAgendamento) {
        toHandleItem.dataAgendamento = dataAgendamento;
      }

      if (dataLimite) {
        toHandleItem.dataLimite = dataLimite;
      }

      const newItemsArray = serviceOrderItemsGenerated.filter(
        (item) => item.index !== index,
      );
      newItemsArray.push(toHandleItem);

      setServiceOrderItemsGenerated(
        newItemsArray.sort((a, b) => {
          if (a.index > b.index) {
            return 1;
          }
          if (b.index > a.index) {
            return -1;
          }
          return 0;
        }),
      );
    },
    [serviceOrderItemsGenerated],
  );

  const handleGenerateServiceOrder = useCallback(async (): Promise<boolean> => {
    try {
      const response = await montador.post(
        '/GerarOS',
        {
          serviceOrder: serviceOrderItemsGenerated,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );

      if (response.status === 200) {
        addToast({
          type: 'success',
          title: response.data[0].message,
          description: response.data[0].details,
        });

        setTimeout(() => {
          history.go(0)
        }, 2000)
        return true;
      }

      return false;
    } catch (error) {
      const { message } = error as { message: string };
      addToast({
        type: 'error',
        title: 'Error',
        description: message,
      });
      return false;
    }
  }, [serviceOrderItemsGenerated, token, addToast]);

  return (
    <ServiceOrderContext.Provider
      value={{
        loading,
        serviceOrders,
        serviceOrderItems,
        getServiceOrderList,
        getServiceOrderItems,
        serviceOrderItemImages,
        serviceOrderItemDetails,
        getServiceOrderItemImages,
        getServiceOrderItemDetails,
        handleGenerateServiceOrder,
        serviceOrderItemsGenerated,
        clearServiceOrderItemsGenerateds,
        handleServiceOrderItemsGenerateds,
        handleOneServiceOrderItemGenerated,
      }}
    >
      {children}
    </ServiceOrderContext.Provider>
  );
};

export function useServiceorder(): IContext {
  const context = useContext(ServiceOrderContext);

  if (!context) {
    throw new Error(
      'useServiceorder must be used within an ServiceOrderContext',
    );
  }

  return context;
}
