import React, {
  useEffect,
  useState,
  useCallback,
  useContext,
  createContext,
} from 'react';

import api from 'services/montador';

import { useAuth } from 'hooks/auth';
import { useToast } from 'hooks/toast';

import IContext from './interfaces/context';
import IAssembler from './interfaces/assembler';
import IMountingType from './interfaces/mountingType';

const MontagemContext = createContext<IContext>({} as IContext);

export const MontagemProvider: React.FC = ({ children }) => {
  const { token } = useAuth();
  const { addToast } = useToast();

  const [loading, setLoading] = useState(false);

  const [assemblers, setAssemblers] = useState<IAssembler[]>([]);
  const [mountingTypes, setMountingTypes] = useState<IMountingType[]>([]);

  const getAssemblers = useCallback(async (): Promise<void> => {
    try {
      setLoading(true);

      const response = await api.get('/ListarMontadores', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      setAssemblers(response.data);
      setLoading(false);
    } catch (error) {
      const { message } = error as { message: string };
      setLoading(false);
      addToast({
        type: 'error',
        title: 'Erro!',
        description: message,
      });
    }
  }, [token, addToast]);

  const getMountingTypes = useCallback(async (): Promise<void> => {
    try {
      setLoading(true);

      const response = await api.get('/ListarTiposdeMontagens', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      setMountingTypes(response.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      //console.log(error);
    }
  }, [token]);

  useEffect(() => {
    function getData(): void {
      getAssemblers();
      getMountingTypes();
    }

    getData();
  }, [getAssemblers, getMountingTypes]);

  return (
    <MontagemContext.Provider
      value={{
        loading,
        assemblers,
        mountingTypes,
        getAssemblers,
        getMountingTypes,
      }}
    >
      {children}
    </MontagemContext.Provider>
  );
};

export function useMontagem(): IContext {
  const context = useContext(MontagemContext);

  if (!context) {
    throw new Error('useMontagem must be used within an MontagemContext');
  }

  return context;
}
