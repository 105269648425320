import styled from 'styled-components';
import { main } from 'styles/colors';

interface IConteinerProps {
  color?: string;
}

export const Container = styled.button<IConteinerProps>`
  border: 0;
  background: ${main.WHITE02};

  display: flex;
  flex-direction: row;

  align-items: center;
  justify-content: center;
  text-align: center;

  padding: 8px;
  padding-left: 8px;
  padding-right: 8px;

  color: ${main.BLACK};
  width: 100%;
  height: 100%;
  border-left: 1px dotted ${main.GRAY};

  transition: background 0.2s;

  > div {
    height: 100%;

    display: flex;
    flex-direction: row;

    text-align: center;
    align-items: center;
    justify-content: center;

    > svg {
      margin: 0;
      padding: 0;
    }
  }

  > p {
    display: flex;
    flex: 1;

    justify-content: center;
    text-align: center;

    font-size: 13px;
    font-weight: bold;
    margin-right: 5px;
  }

  &:hover {
    background-color: ${(props) => (props.color ? props.color : main.WHITE03)};
    color: ${(props) => (props.color ? main.WHITE : main.BROWN)};
  }
`;
