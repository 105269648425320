import styled, { css } from 'styled-components';
import { lighten } from 'polished';

import { main } from 'styles/colors';

import IStyledContainerProperties from './interfaces/styledContainerProperties';

export const Container = styled.div<IStyledContainerProperties>`
  width: 100%;
  margin-top: 30px;
  ${(props) =>
    props.height
      ? css`
          max-height: ${props.height};
        `
      : css`
          max-height: 250px;
        `}

  overflow-x: auto;
  overflow-y: auto;

  scrollbar-color: ${main.GREEN} ${main.LIGHTGRAY};
  scrollbar-width: thin;

  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    position: relative;
  }

  &::-webkit-scrollbar-track {
    background-color: ${main.LIGHTGRAY};
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${main.GREEN};
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: ${lighten(0.05, main.GREEN)};
  }

  > table {
    width: 100%;
    margin: 0 auto;
    border-collapse: collapse;
    border: 1px solid ${main.GRAY};

    font-size: 12px;

    > caption {
      text-align: left;
      text-transform: uppercase;
      font-weight: bold;
    }

    th,
    td {
      padding: 5px;
      text-align: center;
      border-bottom: 1px solid ${main.GRAY};
    }

    th {
      font-size: 14px;
    }

    td {
      > main {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: center;

        > section {
          margin: 0 auto;
          margin-top: 8px;
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          grid-gap: 20px;
          align-items: center;
          justify-content: center;

          button {
            width: 140px;
          }
        }
      }

      > div {
        margin: 0 auto;
        padding: 0;
        width: 70px;

        input {
          text-align: center;
          font-size: 11px;
        }
      }
    }

    > tfoot {
      justify-content: flex-end;
      align-items: flex-end;

      button {
        width: 90px;
        height: 24px;
        align-self: flex-end;
        font-size: 10px;
      }
    }
  }
`;
