import React, { useState, useCallback } from 'react';

import montador from 'services/montador';

import { useAuth } from 'hooks/auth';
import { useModal } from 'hooks/modal';
import { useToast } from 'hooks/toast';
import { useContrato } from 'pages/Montagem/LancarOS/hooks/contrato';

import Textarea from 'components/inputs/Textarea';
import Button from 'components/buttons/Button';
import Loader from 'components/utils/Loader';

import { main } from 'styles/colors';

import { Container } from './styles';

import ServicesOrder from './components/InnerServiceOderList';
import ItemsTable from './components/InnerItemsTable';
import ServiceOrderTable from './components/InnerServiceOrderItemsTable';
import InnerCardsSection from './components/InnerCardsSection';

const InnerServiceOrder: React.FC = () => {
  const { token } = useAuth();
  const { onShowModal } = useModal();
  const { addToast } = useToast();
  const { contractItems, loading } = useContrato();

  const [observation, setObservation] = useState('');
  const [observationError, setObservationError] = useState('');

  const handleServiceOrder = useCallback(async (): Promise<void> => {
    try {
      if (observation === '') {
        setObservationError('campo obrigatorio');
        return;
      }

      setObservationError('');

      const response = await montador.post(
        '/GerarOS',
        {
          observation,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );

      alert(11)

      onShowModal({
        title: 'Resposta',
        content: <p>{response.data}</p>,
      });
    } catch (error) {
      const { message } = error as { message: string };
      addToast({
        type: 'error',
        title: 'Erro!',
        description: message,
      });
    }
  }, [observation, token, onShowModal, addToast]);

  if (loading) {
    return (
      <Container>
        <h4>Gerar Ordem de Serviço</h4>

        <Loader color={main.GREEN} />
      </Container>
    );
  }

  return (
    <Container>
      <h4>Gerar Ordem de Serviço</h4>
      {contractItems.length === 0 ? (
        <main>
          <span>Faça uma Busca...</span>
        </main>
      ) : (
        <main>
          <section>
            <ItemsTable />
          </section>

          {/* <section>
            <ServicesOrder />
          </section> */}

          {/* <section>
            <ServiceOrderTable />
          </section> */}

          {/* <section>
            <InnerCardsSection />
          </section> */}

          <section>
            <Textarea
              name="observacaoOS"
              label="Observação O.S."
              value={observation}
              onChange={(event) => setObservation(event.target.value)}
              error={observationError}
            />

            <Button color={main.GREEN} onClick={handleServiceOrder}>
              Enviar Observação
            </Button>
          </section>
        </main>
      )}
    </Container>
  );
};

export default InnerServiceOrder;
