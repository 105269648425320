import styled from 'styled-components';
import { lighten } from 'polished';

import { main } from 'styles/colors';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex: 1;
  flex-direction: row;

  > div {
    margin-bottom: 10px;
  }

  > section {
    display: flex;
    height: 420px;
    flex-direction: column;
    flex: 1;
    margin: 8px;
    padding: 8px;

    overflow-y: scroll;
    overflow-x: hidden;

    scrollbar-color: ${main.GREEN} ${main.WHITE};
    scrollbar-width: thin;

    &::-webkit-scrollbar {
      width: 6px;
      position: relative;
    }

    &::-webkit-scrollbar-track {
      background-color: ${main.WHITE};
    }

    &::-webkit-scrollbar-thumb {
      background-color: ${main.GREEN};
    }

    &::-webkit-scrollbar-thumb:hover {
      background-color: ${lighten(0.05, main.GREEN)};
    }
  }
`;

export const DateSelectContainer = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;

  margin-top: 12px;
  padding: 8px;

  border: 1px solid ${main.BROWNBAGE};
  border-radius: 4px;

  > span {
    text-transform: uppercase;
    font-weight: bold;
    font-size: 16px;
    border-bottom: 1px solid ${main.BROWNBAGE};
    width: 100%;
    margin-bottom: 8px;
  }

  > div {
    width: 100%;
    display: flex;
    flex: 1;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-around;

    > button {
      margin-right: 12px;
    }
  }
`;

export const AssemblerSelectContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;

  margin-top: 12px;

  > button {
    width: 100px;
    margin-left: 8px;
  }
`;

export const Table = styled.table`
  width: 100%;
  margin: 0 auto;
  border-collapse: collapse;
  border: 1px solid ${main.GRAY};
  font-size: 14px;
  text-align: left;

  > caption {
    text-transform: uppercase;
    font-weight: bold;
  }

  th,
  td {
    padding: 5px;
    border: 1px solid ${main.GRAY};
  }

  button {
    width: 50px;
    margin: 0 auto;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  margin-top: 16px;

  > div {
    width: 45%;
  }
`;
