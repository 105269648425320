import React, { useState, useEffect } from 'react';

import { useTabmenu } from 'pages/Montagem/LancarOS/hooks/tabmenu';

import { TabContainer, TabButton, ContentContainer } from './styles';

import IComponentProperties from './interfaces/componentProperties';
import ITabContainerProperties from './interfaces/tabContainerProperties';

const BaseTabContainer: React.FC<IComponentProperties> = ({ component }) => {
  const { activeTab, handleChangeActiveTab } = useTabmenu();

  const [tabs] = useState(() => {
    const temp: string[] = [];

    component.forEach(({ tabTitle }: ITabContainerProperties) => {
      temp.push(tabTitle);
    });

    return temp;
  });

  useEffect(() => {
    function setDefaultActiveTab(): void {
      handleChangeActiveTab(tabs[0]);
    }

    setDefaultActiveTab();
  }, [handleChangeActiveTab, tabs]);

  return (
    <>
      <TabContainer>
        <header>
          {component.map(
            ({ tabTitle, tabIcon: Icon }: ITabContainerProperties) => (
              <TabButton
                key={tabTitle}
                type="button"
                onClick={() => handleChangeActiveTab(tabTitle)}
                active={activeTab === tabTitle}
              >
                <Icon size={16} />
                {tabTitle}
              </TabButton>
            ),
          )}
        </header>

        {component.map(
          ({ tabTitle, tabContent: Content }: ITabContainerProperties) => (
            <ContentContainer key={tabTitle} active={activeTab === tabTitle}>
              <Content />
            </ContentContainer>
          ),
        )}
      </TabContainer>
    </>
  );
};

export default BaseTabContainer;
