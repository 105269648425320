import styled, { css } from 'styled-components';

import { main } from 'styles/colors';

interface ITabButtonProps {
  active?: boolean;
}

export const Container = styled.div`
  > h3 {
    position: relative;
    top: 0;
    left: 0;

    border-bottom: 1px solid ${main.GRAY};
  }

  > header {
    margin-top: 16px;
  }

  > section {
    margin-top: 30px;
    width: 100%;
  }
`;

export const TabContainer = styled.div`
  width: 100%;
  display: flex;
  flex: 1;
  flex-direction: column;

  > header {
    overflow: hidden;
    border-bottom: none;
  }

  > main {
    padding: 16px;
    border: 1px solid ${main.GRAY};
  }
`;

export const TabButton = styled.button<ITabButtonProps>`
  cursor: pointer;
  outline: none;
  border: none;
  background-color: ${main.WHITE};

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: center;

  float: left;

  padding: 12px;
  text-align: center;
  transition: 0.3s;

  &:hover {
    color: ${main.GREEN};
  }

  ${(props) =>
    props.active &&
    css`
      border: 1px solid ${main.GRAY};
      border-top: 3px solid ${main.GREEN};
      border-bottom: none;
      color: ${main.GREEN};
    `}

  svg {
    margin-right: 5px;
  }
`;
