import React, { useState } from 'react';
import { FiPlusCircle, FiCalendar } from 'react-icons/fi';

import { useAuth } from 'hooks/auth';
import IShop from 'hooks/auth/interfaces/shop';

import IconButton from 'components/buttons/IconButton';
import Select from 'components/inputs/Select';
import Input from 'components/inputs/Input';

import Datepicker from 'components/inputs/Datepicker';

import { main, states } from 'styles/colors';

const Avulsos: React.FC = () => {
  const { user } = useAuth();

  const [shopCode, setShopCode] = useState<number>();
  const [shopCodeError, setShopCodeError] = useState('');

  const [startDate, setStartDate] = useState(new Date());

  return (
    <>
      <h5>Avulsos</h5>

      <section>
        <main>
          <div>
            <Select
              label="Lojas"
              name="lojas"
              error={shopCodeError}
              onChange={(event) => {
                if (event.target.value === ' ') {
                  return;
                }

                setShopCode(
                  user.Lojas.filter(
                    (loja: IShop) =>
                      loja.CodLoja === Number(event.target.value),
                  )[0].CodLoja,
                );
              }}
            >
              <option value=" "> </option>

              {user.Lojas.map((loja: IShop) => (
                <option key={String(loja.CodLoja)} value={loja.CodLoja}>
                  {`${loja.CodLoja} - ${loja.Empresa}`}
                </option>
              ))}
            </Select>
          </div>

          <div>
            <Input name="tipocomissao" label="Tipo de Comissão" />
          </div>

          <div>
            <Input name="sequencial" label="Sequêncial" />
          </div>

          <div>
            <Datepicker
              icon={FiCalendar}
              label="Data"
              color={main.GREEN}
              selected={startDate}
              onChange={(date: Date) => setStartDate(date)}
            />
          </div>
        </main>
        <footer>
          <div>
            <IconButton
              color={states.SUCCEES}
              label="Adicionar"
              icon={FiPlusCircle}
            />
          </div>
        </footer>
      </section>
    </>
  );
};

export default Avulsos;
