import React, { useState, useCallback } from 'react';
import { format as formatDate } from 'date-fns';

import { FiPlusCircle, FiChevronRight, FiMinusCircle } from 'react-icons/fi';

import { useMontagem } from 'pages/Montagem/LancarOS/hooks/montagem';
import { useServiceorder } from 'pages/Montagem/LancarOS/hooks/serviceorder';

import IAssembler from 'pages/Montagem/LancarOS/hooks/montagem/interfaces/assembler';

import IconButton from 'components/buttons/IconButton';
import Select from 'components/inputs/Select';
import Datepicker from 'components/inputs/Datepicker';

import { main, states } from 'styles/colors';

import { Container } from './styles';

import { TDateFormType } from '../_shared/interfaces/dateFormType';

import IComponentProperties from './interfaces/componentProperties';

const InnerTableRow: React.FC<IComponentProperties> = ({
  isGeneralOperation,
  index,
  contrato,
  item,
  descricao,
}) => {
  const { assemblers, mountingTypes } = useMontagem();
  const { handleOneServiceOrderItemGenerated } = useServiceorder();

  const [currentForm, setCurrentForm] = useState<TDateFormType>('scheduling');
  const [schedulingDate, setSchedulingDate] = useState(new Date());
  const [limitDate, setLimitDate] = useState(new Date());

  const [selectedFitter, setSelectedFitter] = useState<IAssembler>();
  const [selectedFitterError, setSelectedFitterError] = useState('');

  const [fittersList, setFittersList] = useState<IAssembler[]>([]);

  const handleChangeCurrentForm = useCallback((form: TDateFormType) => {
    setCurrentForm(form);
  }, []);

  const handleSelectedMountingType = useCallback(
    (idMountingType: number) => {
      handleOneServiceOrderItemGenerated({
        index,
        idTipoMontagem: idMountingType,
      });
    },
    [handleOneServiceOrderItemGenerated, index],
  );

  const handleSelectedDate = useCallback(
    (date: Date) => {
      if (currentForm === 'scheduling') {
        handleOneServiceOrderItemGenerated({
          index,
          dataAgendamento: formatDate(date, 'yyyy-MM-dd HH:mm:ss'),
        });
      } else if (currentForm === 'limit') {
        handleOneServiceOrderItemGenerated({
          index,
          dataLimite: formatDate(date, 'yyyy-MM-dd 00:00:00'),
        });
      }
    },
    [currentForm, handleOneServiceOrderItemGenerated, index],
  );

  const handleSelectedFitter = useCallback(() => {
    if (selectedFitter) {
      const fittersCode: number[] = [];
      const tempFitters = fittersList;

      const selectedfitter = fittersList.filter(
        (fitter) => fitter.CodFunc === selectedFitter.CodFunc,
      )[0];

      if (selectedfitter) {
        setSelectedFitterError('Montador já selecionado!');
        return;
      }

      tempFitters.push(selectedFitter);

      tempFitters.forEach((fitter) => {
        fittersCode.push(fitter.CodFunc);
      });

      setFittersList(tempFitters);
      setSelectedFitterError('');
      setSelectedFitter(undefined);
      handleOneServiceOrderItemGenerated({ index, montadores: fittersCode });
    } else {
      setSelectedFitterError('Selecione um montador!');
    }
  }, [fittersList, selectedFitter, handleOneServiceOrderItemGenerated, index]);

  const handleRemoveOneFeitterFromList = useCallback(
    (codFunc: number) => {
      const newFittersList = fittersList.filter(
        (fitter) => fitter.CodFunc !== codFunc,
      );

      setFittersList(newFittersList);
    },
    [fittersList],
  );

  return (
    <Container>
      <td>{contrato}</td>
      <td>{item}</td>
      <td>{descricao}</td>

      {!isGeneralOperation && (
        <>
          <td>
            <div>
              <Select
                name="tipomontagem"
                onChange={(event) => {
                  handleSelectedMountingType(Number(event.target.value));
                }}
              >
                <option value=" "> </option>

                {mountingTypes.map((option) => (
                  <option
                    key={String(option.IDMotagemTipo)}
                    value={option.IDMotagemTipo}
                  >
                    {option.Descricaotipomontagem}
                  </option>
                ))}
              </Select>
            </div>
          </td>

          <td>
            <section>
              <div
                style={{
                  marginRight: '12px',
                }}
              >
                <label
                  htmlFor="scheduling"
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}
                >
                  <input
                    checked={currentForm === 'scheduling'}
                    onClick={() => handleChangeCurrentForm('scheduling')}
                    type="radio"
                    id="scheduling"
                    value="scheduling"
                    name="date_form_type"
                    style={{
                      marginRight: '4px',
                    }}
                  />
                  <span
                    style={{
                      display: 'block',
                      fontSize: '16px',
                    }}
                  >
                    Agendamento
                  </span>
                </label>

                <label
                  htmlFor="limit"
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}
                >
                  <input
                    checked={currentForm === 'limit'}
                    onClick={() => handleChangeCurrentForm('limit')}
                    type="radio"
                    id="limit"
                    value="limit"
                    name="date_form_type"
                    style={{
                      marginRight: '4px',
                    }}
                  />
                  <span
                    style={{
                      display: 'block',
                      fontSize: '16px',
                    }}
                  >
                    Data limite
                  </span>
                </label>
              </div>

              <section>
                {currentForm === 'scheduling' && (
                  <Datepicker
                    label="Agendamento"
                    color={main.GREEN}
                    selected={schedulingDate}
                    showTimeSelect
                    dateFormat="dd/MM/yyyy, HH:mm"
                    onChange={(date: Date) => {
                      setSchedulingDate(date);
                      handleSelectedDate(date);
                    }}
                  />
                )}
                {currentForm === 'limit' && (
                  <Datepicker
                    label="Data Limite"
                    color={main.GREEN}
                    selected={limitDate}
                    onChange={(date: Date) => {
                      setLimitDate(date);
                      handleSelectedDate(date);
                    }}
                  />
                )}
              </section>
            </section>
          </td>

          <td>
            <section>
              <main>
                <Select
                  name="monatdores"
                  error={selectedFitterError}
                  onChange={(event) => {
                    setSelectedFitter(
                      assemblers.filter(
                        (assembler) =>
                          assembler.CodFunc === Number(event.target.value),
                      )[0],
                    );
                  }}
                >
                  <option value=" "> </option>

                  {assemblers
                    .sort((a, b) => {
                      if (a.NomeFuncionario > b.NomeFuncionario) {
                        return 1;
                      }

                      if (b.NomeFuncionario > a.NomeFuncionario) {
                        return -1;
                      }
                      return 0;
                    })
                    .map((option) => (
                      <option
                        key={String(option.CodFunc)}
                        value={option.CodFunc}
                      >
                        {`${option.CodFunc} - ${option.NomeFuncionario}`}
                      </option>
                    ))}
                </Select>

                <IconButton
                  icon={FiPlusCircle}
                  color={states.SUCCEES}
                  onClick={handleSelectedFitter}
                />
              </main>

              <ul>
                {fittersList.length === 0 ? (
                  <li>nenhum montador selecionado</li>
                ) : (
                  fittersList.map((montador) => (
                    <li key={String(montador.CodFunc)}>
                      <IconButton
                        icon={FiMinusCircle}
                        color={states.ERROR}
                        onClick={() => {
                          handleRemoveOneFeitterFromList(montador.CodFunc);
                        }}
                      />
                      {`${montador.CodFunc} - ${montador.NomeFuncionario}`}
                    </li>
                  ))
                )}
              </ul>
            </section>
          </td>
        </>
      )}
    </Container>
  );
};

export default InnerTableRow;
